
import React from 'react';
import { Link } from 'react-router-dom';

function PageTabHeader(props) {
    function handleActiveTab(data) {
        props.handleActiveTab(data);
    }

    return (
        <div className="card">
            <div className="card-body">
                <ul className="nav nav-tabs-custom card-header-tabs" id="pills-tab" role="tablist">
                    {
                        props.tabs.map(function(item, index) {
                            return (
                                <li className="nav-item">
                                    {
                                        (props.anotherDisable) ?
                                            <a className={(props.active == item) ? 'nav-link px-3 active' : 'nav-link px-3 disabled'} href="#" role="tab" onClick={() => handleActiveTab(item)}>{item}</a>
                                        :
                                            <a className={(props.active == item) ? 'nav-link px-3 active' : 'nav-link px-3'} href="#" role="tab" onClick={() => handleActiveTab(item)}>{item}</a>
                                    }
                                </li>
                            )
                        })
                    }
                    {/* <li className="nav-item">
                        <a className="nav-link px-3" data-bs-toggle="tab" href="#dataPegawai" role="tab">Data Pegawai</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link px-3" data-bs-toggle="tab" href="#post" role="tab">Payroll</a>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}

export default PageTabHeader;