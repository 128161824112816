import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import PageTitle from '../../../Components/Page/PageTitle';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import APP_MODEL_REIMBURSE from '../../../Models/Finance/reimburse';
import APP_MODEL_REFERENSI from '../../../Models/Referensi/referensi';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';

function Add() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
        let urlAction       = useParams();
    /* ----------
    Page Vars
    ------------------ */
        const [pageTitle, setPageTitle] = useState("Input Reimburse");
        const [pageID, setPageID] = useState(60);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const defaultOption = { value: '0', label: ' - Pilih -' };
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputKaryawan, setInputKaryawan] = useState(defaultOption);
        const [inputTanggal, setInputTanggal] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputTanggalEffekif, setInputTanggalEffektif] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputJenisReimburse, setInputJenisReimburse] = useState(defaultOption);
        const [inputKeterangan, setInputKeterangan] = useState('');
        const [inputItems, setInputItems] = useState([{ keterangan: '-', nominal: 0 }]);
        const [totalPengajuan, setTotalPengajuan] = useState(0);
        // -----------------
        const [dataKaryawan, setDataKaryawan] = useState([defaultOption]);
        const [dataJenisReimburse, setDataJenisReimburse] = useState([defaultOption]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let resCollections = await APP_MODEL_REIMBURSE.getCollections();

            dataKaryawan.push(...resCollections.collections.karyawan);
            dataJenisReimburse.push(...resCollections.collections.jenis_reimburse);

            setIsLoading(false);

            return () => {
                setDataKaryawan([]);
                setDataJenisReimburse([]);
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }

        function handleUpdateKeterangan(value, item, index) {
            const updateItem = {
                ...item,
                keterangan: value
            }

            const newBenefit = [...inputItems];
            newBenefit[index] = updateItem;
            setInputItems(newBenefit);
        }

        function handleUpdateNominal(value, item, index) {
            const updateItem = {
                ...item,
                nominal: value
            }
            
            const newBenefit = [...inputItems];
            newBenefit[index] = updateItem;

            handleTotal(newBenefit);
            setInputItems(newBenefit);
        }

        function handleTotal(data) {
            let total = data.reduce((total, currentItem) =>  total = eval(total) + eval(currentItem.nominal), 0);
            
            setTotalPengajuan(total);
        }

        function handleInsertBenefit(e) {
            e.preventDefault();

            const newItem = { keterangan: '-', nominal: 0 };

            setInputItems([...inputItems, newItem]);
        }

        function handleRemoveBenefit(i) {
            if(inputItems.length == 1) {
                setInputItems([{keterangan: '-', nominal: 0}]);

                setTotalPengajuan(0);
            } else {
                
                const filterItems = inputItems.filter(function (item, index) {
                    return index != i;
                });

                setInputItems(filterItems);
                handleTotal(filterItems);
            }
        }

        const handleSubmit = async (e) => {
            e.preventDefault();

            setIsLoadingSubmit(true);
            
            const formSubmit = {
                karyawan_id: inputKaryawan.value,
                jenis_reimburse_id: inputJenisReimburse.value,
                tanggal_reimburse: inputTanggal,
                tanggal_realisasi: inputTanggalEffekif,
                keterangan: inputKeterangan,
                items: inputItems
            }
           
            let res = await APP_MODEL_REIMBURSE.create(formSubmit);

            if(res.status == '100') {
                navigate("/reimburse/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="select" label="Nama Karyawan" size="2" mandatory={true} val={inputKaryawan} option={dataKaryawan} defaultSelect={inputKaryawan} onChangeValue={(val) => setInputKaryawan(val)}/>
                                                            <FormInput type="date-picker" label="Tanggal Reimburse" size="2" mandatory={true} val={inputTanggal} onChangeValue={(event) => setInputTanggal(event.target.value)}/>
                                                            <FormInput type="date-picker" label="Tanggal Effektif" size="2" mandatory={true} val={inputTanggalEffekif} onChangeValue={(event) => setInputTanggalEffektif(event.target.value)}/>
                                                            <FormInput type="select" label="Jenis Reimburse" size="2" mandatory={true} val={inputJenisReimburse} option={dataJenisReimburse} defaultSelect={inputJenisReimburse} onChangeValue={(val) => setInputJenisReimburse(val)}/>
                                                            <FormInput type="text" label="Keterangan" size="1" mandatory={true} val={inputKeterangan} onChangeValue={(event) => setInputKeterangan(event.target.value)}/>
                                                        </div>
                                                        <div className="row" style={{marginTop: 20}}>
                                                            <div className="table-responsive">
                                                                <table className="table mb-0">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th style={{textAlign: 'center', width: '70%'}}>Benefit</th>
                                                                            <th style={{textAlign: 'center', width: '20%'}}>Jumlah Pengajuan</th>
                                                                            <th style={{textAlign: 'center', width: '10%'}}>Tools</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        inputItems.map(function(item, index) {
                                                                            return (
                                                                                <tr>
                                                                                    <th scope="row" style={{textAlign: 'center'}}>
                                                                                        <FormInput type="text-only" label="Keterangan" mandatory={true} val={item.keterangan} onChangeValue={(event) => handleUpdateKeterangan(event.target.value, item, index)}/>
                                                                                    </th>
                                                                                    <td style={{textAlign: 'right'}}>
                                                                                        <FormInput type="currency-only" align="right" label="Nominal" mandatory={true} val={item.nominal} onChangeValue={(value) => handleUpdateNominal(value, item, index)}/>
                                                                                    </td>
                                                                                    <td style={{textAlign: 'center'}} className="icon-demo-content">
                                                                                        <div className="col-xl-12 col-lg-12 col-sm-12" style={{cursor: 'pointer'}} onClick={() => handleRemoveBenefit(index)}><i className="fas fa-trash"></i></div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                        <tr>
                                                                            <th scope="row" style={{textAlign: 'right', fontWeight: 'bold'}}>
                                                                                Total
                                                                            </th>
                                                                            <td style={{textAlign: 'right', fontWeight: 'bold', marginRight: 50}}>
                                                                                { APP_HELPERS_COMMON.formatCurrency(totalPengajuan) }
                                                                            </td>
                                                                            <td style={{textAlign: 'center'}} className="icon-demo-content">
                                                                                &nbsp;
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" style="btn-success" label="Tambah Benefit" handleSubmit={handleInsertBenefit} />
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row" style={{marginTop: -15}}>
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Simpan" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Add;