import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import PageTitle from '../../../Components/Page/PageTitle';
import APP_MODEL_THR from '../../../Models/Payroll/thr';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import APP_STORAGE from '../../../Storage';
import APP_CONFIG from '../../../Constants/App';

function Report() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
    /* ----------
    Page Vars
    ------------------ */
        const [pageTitle, setPageTitle] = useState("Laporan THR");
        const [pageID, setPageID] = useState(118);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        const defaultOption = { value: '0', label: ' - Pilih -' };
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputTanggal, setInputTanggal] = useState(defaultOption);
        const [inputCabang, setInputCabang] = useState(defaultOption);
        // -----------------
        const [dataTanggal, setDataTanggal] = useState([]);
        const [dataCabang, setDataCabang] = useState([defaultOption]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let res = await APP_MODEL_THR.get_collection();
            
            if(res.status === "401") {
                APP_STORAGE.clearLocalStorage();
                navigate("/login");
            }
            
            if(res.collections.periode.length > 0) {
                setInputTanggal({ value: res.collections.periode[0].value, label: res.collections.periode[0].label });
            }
            
            dataTanggal.push(...res.collections.periode);
            dataCabang.push(...res.collections.cabang);
            
            setIsLoading(false);

            return () => {
                
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }
        
        const handleSubmit = async (e) => {
            e.preventDefault();
            setIsLoadingSubmit(true);

            let params = inputTanggal.value + "|" + inputCabang.value;

            let accessToken = APP_STORAGE.getAccessToken();
            let baseUrl = (APP_CONFIG.isProduction) ? APP_CONFIG.EndPointProduction : APP_CONFIG.EndPointStaging;
            
            var params_url = [
                'height='+window.innerHeight,
                'width='+window.innerWidth,
                'fullscreen=yes'
            ].join(',');

            let xhr = new XMLHttpRequest();
            xhr.open('GET', baseUrl + '/api/v1/payroll/thr/report_thr/' + btoa(params), true);
            xhr.setRequestHeader("Authorization", "Bearer " + accessToken);

            xhr.onload = function(e) {
                if (this['status'] === 200) {          
                    var blob = new Blob([this['response']],  {type: 'text/html' });
                    var url = URL.createObjectURL(blob);
                    var printWindow = window.open(url, '_blank', params_url, 'noopener,noreferrer');
                }

                setIsLoadingSubmit(false);
            };
      
            xhr.send();
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="select" label="Periode" size="2" mandatory={true} val={inputTanggal} option={dataTanggal} defaultSelect={inputTanggal} onChangeValue={(event) => setInputTanggal(event)}/>
                                                            <FormInput type="select" label="Kantor Cabang" size="2" mandatory={true} val={inputCabang} option={dataCabang} defaultSelect={inputCabang} onChangeValue={(event) => setInputCabang(event)}/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Preview" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Report;