import CryptoJS from 'crypto-js';
import APP_CONSTANT from '../Constants/App';

class CommonHelpers {
    crypt = (str) => {
        let encStr = CryptoJS.AES.encrypt(str, 'M1tr4MO70r');
        
        return btoa(encStr.toString());
    }
    
    dcrypt = (str) => {
        let def       = atob(str);
        let bytes     = CryptoJS.AES.decrypt(def, 'M1tr4MO70r');
        let decStr    = bytes.toString(CryptoJS.enc.Utf8);

        return decStr;
    }

    doEncrypt = (str) => {
        return (str);
        // let encStr = CryptoJS.AES.encrypt(str.toString(), APP_CONSTANT.cryptoSecretKey);
        
        // return this.slashReplace(encStr.toString());
    }

    doDecrypt = (str) => {
        return (str);
        // let base64    = this.slashReverse(str.toString());
        // let bytes     = CryptoJS.AES.decrypt(base64, APP_CONSTANT.cryptoSecretKey);
        // let decStr    = bytes.toString(CryptoJS.enc.Utf8);
        // console.log('datanya :', bytes)
        // return decStr;
    }

    getCurrentDate = () => {
        let newDate = new Date()

        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";

        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`
    }

    datePickerConvert = (data) => {
        let date = data.toLocaleDateString("id-ID").split("/").map(x => x.padStart(2, "0")).join("/");

        return date;
    }

    formatCurrency = (value) => {
        if(value) {
            let val = (value/1).toFixed(0)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
            return 0;
        }
    }

    getBadge = (data) => {
        switch(data) {
            case 'New':
                return 'badge rounded-pill bg-success';
            case 'Done':
                return 'badge rounded-pill bg-primary';
            case 'Aktif':
                return 'badge rounded-pill bg-primary';
            case 'Non Aktif':
                return 'badge rounded-pill bg-danger';
            case 'Approve':
                return 'badge rounded-pill bg-success';
            case 'Pelunasan':
                return 'badge rounded-pill bg-warning';
            case 'Reguler':
                return 'badge rounded-pill bg-warning';
            default:
                return 'badge rounded-pill bg-primary';
        }
    }

    formatYMD(x, y){
        var z = {
            M: x.getMonth() + 1,
            d: x.getDate(),
            h: x.getHours() > 10 ? x.getHours() : '0'+x.getHours(),
            m: x.getMinutes() > 10 ? x.getMinutes() : '0'+x.getMinutes(),
            s: x.getSeconds() > 10 ? x.getSeconds() : '0'+x.getSeconds()
        };
        y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
            return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
        });
    
        return y.replace(/(y+)/g, function(v) {
            return x.getFullYear().toString().slice(-v.length)
        });
    }

    reportGET(url, success) {
        let token = '';

        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (success) success(xhr.response);
            }
        };
        xhr.send(null);
    }
}   

const Common = new CommonHelpers();

export default Common;
