import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import PageTitle from '../../../Components/Page/PageTitle';
import CellAction from '../../../Components/DatatTableGrid/CellAction';
import PageActionDirect from '../../../Components/Page/PageActionDirect';
import APP_STORAGE from '../../../Storage';
import PageTable from '../../../Components/PageTable';
import ModelReferensiCabang from '../../../Models/Referensi/cabang';
import CommonEndpoint from '../../../Models/common';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import APP_HELPERS_COMMON from '../../../Helpers/Common';

function Edit(props) {
    /* ----------
    URL Params
    ------------------ */
    let navigate        = useNavigate();
    let urlAction       = useParams();
    let urlID           = urlAction.id;
/* ----------
Page Vars
------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Edit Cabang");
        const [pageID, setPageID] = useState(34);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('Data Pribadi');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputKodeCabang, setInputKodeCabang] = useState('');
        const [inputNamaCabang, setInputNamaCabang] = useState('');
        const [inputAlamat, setInputAlamat] = useState('');
        const [inputNoTelp, setInputNoTelp] = useState('');
        const [inputNoHp, setInputNoHp] = useState('');
        const [inputNoFax, setInputNoFax] = useState('');
        const [inputEmail, setInputEmail] = useState('');
        const [inputStatus, setInputStatus] = useState(0);
        // -----------------
        const defaultOption = { value: '0', label: ' - Pilih -' };
        const [dataStatus, setDataStatus] = useState([defaultOption]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let data = await ModelReferensiCabang.get_detail(dataID);
            let reSelectStatus = await ModelReferensiCabang.getSelectStatus();
            
            setInputStatus({ value: data.data.status, label: data.data.keterangan_status });
            
            setInputKodeCabang(data.data.kode_cabang);
            setInputNamaCabang(data.data.nama_cabang);
            setInputAlamat(data.data.alamat);
            setInputNoTelp(data.data.no_telp);
            setInputNoHp(data.data.no_hp);
            setInputNoFax(data.data.no_fax);
            setInputEmail(data.data.email);

            dataStatus.push(...reSelectStatus.data);

            setIsLoading(false);
            return () => {
                
            };
        }, []);
        
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                // return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }

        const handleSubmit = async (e) => {
            e.preventDefault();
            setIsLoadingSubmit(true);
            
            const formSubmit = {
                cabang_id: dataID,
                kode_cabang: inputKodeCabang,
                nama_cabang: inputNamaCabang,
                alamat: inputAlamat,
                no_telp: inputNoTelp,
                no_hp: inputNoHp,
                no_fax: inputNoFax,
                email: inputEmail,
                status: inputStatus.value,
            }
            let res = await ModelReferensiCabang.update(formSubmit);
            
            if(res.status === '100') {
                navigate("/cabang/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));

                // setAlertMessage(res.message);
                // setAlertOpen(true);
                // setAlertStyle("success");

                // setTimeout(() => {
                //     navigate("/cabang/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
                // }, 2000);
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="text" label="Kode Cabang" size="2" mandatory={true} val={inputKodeCabang} readonly={true} onChangeValue={(event) => setInputKodeCabang(event.target.value)}/>
                                                            <FormInput type="text" label="Nama Cabang" size="2" mandatory={true} val={inputNamaCabang} readonly={true} onChangeValue={(event) => setInputNamaCabang(event.target.value)}/>
                                                            <FormInput type="text" label="Alamat" size="3" mandatory={false} val={inputAlamat} onChangeValue={(event) => setInputAlamat(event.target.value)}/>
                                                            <FormInput type="text" label="No Telp" size="2" mandatory={false} val={inputNoTelp} onChangeValue={(event) => setInputNoTelp(event.target.value)}/>
                                                            <FormInput type="text" label="No Hp" size="2" mandatory={false} val={inputNoHp} onChangeValue={(event) => setInputNoHp(event.target.value)}/>
                                                            <FormInput type="text" label="No Fax" size="2" mandatory={false} val={inputNoFax} onChangeValue={(event) => setInputNoFax(event.target.value)}/>
                                                            <FormInput type="text" label="Email" size="2" mandatory={false} val={inputEmail} onChangeValue={(event) => setInputEmail(event.target.value)}/>
                                                            <FormInput type="select" label="Status" size="2" mandatory={true} val={inputStatus} option={dataStatus} defaultSelect={inputStatus} onChangeValue={(val) => setInputStatus(val)}/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Update" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Edit;