import axios from 'axios';
import APP_CONFIG from '../Constants/App';
import APP_STORAGE from '../Storage';

class ApiCommon {
    async get_pagging(url, params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(url, params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }
}

const ReferensiCommon = new ApiCommon();

export default ReferensiCommon;
