import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import PageTitle from '../../../Components/Page/PageTitle';
import APP_MODEL_PENGGAJIAN from '../../../Models/Payroll/penggajian';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import APP_STORAGE from '../../../Storage';
import APP_CONFIG from '../../../Constants/App';

function ReportBPJS() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
    /* ----------
    Page Vars
    ------------------ */
        const [pageTitle, setPageTitle] = useState("Laporan BPJS");
        const [pageID, setPageID] = useState(118);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        const defaultOption = { value: '0', label: ' - Pilih -' };
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputTanggal, setInputTanggal] = useState(defaultOption);
        const [inputJenis, setInputJenis] = useState(defaultOption);
        // -----------------
        const [dataTanggal, setDataTanggal] = useState([]);
        const [dataJenis, setDataJenis] = useState([]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let res = await APP_MODEL_PENGGAJIAN.get_bpjs_collections();
            
            if(res.status === "401") {
                APP_STORAGE.clearLocalStorage();
                navigate("/login");
            }
            
            if(res.collections.periode.length > 0) {
                setInputTanggal({ value: res.collections.periode[0].value, label: res.collections.periode[0].label });
                setInputJenis({ value: res.collections.jenis_laporan[0].value, label: res.collections.jenis_laporan[0].label });
            }
            
            dataTanggal.push(...res.collections.periode);
            dataJenis.push(...res.collections.jenis_laporan);
            
            setIsLoading(false);

            return () => {
                
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }
        
        const handleSubmit = async (e) => {
            e.preventDefault();
            setIsLoadingSubmit(true);

            let params = inputTanggal.value + "|" + inputJenis.value;
            
            let resDownload = await APP_MODEL_PENGGAJIAN.reportBPJS(params);

            if(resDownload.status !== '100') {
                setAlertMessage("Report pph gagal");
                setAlertOpen(true);
                setAlertStyle("error");
            }

            setIsLoadingSubmit(false);
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="select" label="Periode" size="2" mandatory={true} val={inputTanggal} option={dataTanggal} defaultSelect={inputTanggal} onChangeValue={(event) => setInputTanggal(event)}/>
                                                            <FormInput type="select" label="Jenis Form" size="2" mandatory={true} val={inputJenis} option={dataJenis} defaultSelect={inputJenis} onChangeValue={(event) => setInputJenis(event)}/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Preview" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportBPJS;