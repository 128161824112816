import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import PageTitle from '../../../Components/Page/PageTitle';
import CellAction from '../../../Components/DatatTableGrid/CellAction';
import PageActionDirect from '../../../Components/Page/PageActionDirect';
import APP_STORAGE from '../../../Storage';
import PageTable from '../../../Components/PageTable';
import ModelReferensiJabatan from '../../../Models/Referensi/jabatan';
import CommonEndpoint from '../../../Models/common';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import APP_HELPERS_COMMON from '../../../Helpers/Common';

function Edit(props) {
    /* ----------
    URL Params
    ------------------ */
    let navigate        = useNavigate();
    let urlAction       = useParams();
    let urlID           = urlAction.id;
        /* ----------
        Page Vars
        ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Ubah Jabatan");
        const [pageID, setPageID] = useState(39);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputNamaJabatan, setInputNamaJabatan] = useState('');
        const [inputTunjanganJabatan, setInputTunjanganJabatan] = useState(0);
        const [inputTunjanganUM, setInputTunjanganUM] = useState(0);
        const [inputTunjanganTransport, setInputTunjanganTransport] = useState(0);
        const [inputTunjanganPulsa, setInputTunjanganPulsa] = useState(0);
        const [inputTunjanganKost, setInputTunjanganKost] = useState(0);
        const [inputUpahHarian, setInputUpahHarian] = useState(0);
        const [inputStatus, setInputStatus] = useState(0);
        // -----------------
        const defaultOption = { value: '0', label: ' - Pilih -' };
        const [dataStatus, setDataStatus] = useState([defaultOption]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let data = await ModelReferensiJabatan.get(dataID);
            let reSelectStatus = await ModelReferensiJabatan.getSelectStatus();
            
            setInputStatus({ value: data.data.status, label: data.data.keterangan_status });
            setInputNamaJabatan(data.data.nama_jabatan);
            setInputTunjanganJabatan(data.data.tunjangan_jabatan);
            setInputTunjanganUM(data.data.tunjangan_makan);
            setInputTunjanganTransport(data.data.tunjangan_transport);
            setInputTunjanganPulsa(data.data.tunjangan_pulsa);
            setInputTunjanganKost(data.data.tunjangan_kost);
            setInputUpahHarian(data.data.gaji_harian);

            dataStatus.push(...reSelectStatus.data);

            setIsLoading(false);
            return () => {
                
            };
        }, []);
        
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                // return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }

        const handleSubmit = async (e) => {
            e.preventDefault();
            setIsLoadingSubmit(true);
            
            const formSubmit = {
                jabatan_id: dataID,
                nama_jabatan: inputNamaJabatan,
                tunjangan_jabatan: inputTunjanganJabatan,
                tunjangan_makan: inputTunjanganUM,
                tunjangan_transport: inputTunjanganTransport,
                tunjangan_pulsa: inputTunjanganPulsa,
                tunjangan_kost: 0, //inputTunjanganKost,
                gaji_harian: inputUpahHarian,
                status: inputStatus.value,
            }
            let res = await ModelReferensiJabatan.update(formSubmit);
            
            if(res.status == '100') {
                navigate("/jabatan/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));

                // setAlertMessage(res.message);
                // setAlertOpen(true);
                // setAlertStyle("success");

                // setTimeout(() => {
                //     navigate("/jabatan/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
                // }, 2000);
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="text" label="Nama Jabatan" size="2" mandatory={true} readonly={true} val={inputNamaJabatan} onChangeValue={(event) => setInputNamaJabatan(event.target.value)}/>
                                                            <FormInput type="text" label="Tunjangan Jabatan" size="2" mandatory={false} val={inputTunjanganJabatan} onChangeValue={(event) => setInputTunjanganJabatan(event.target.value)}/>
                                                            <FormInput type="text" label="Uang Makan Per Hari" size="2" mandatory={false} val={inputTunjanganUM} onChangeValue={(event) => setInputTunjanganUM(event.target.value)}/>
                                                            <FormInput type="text" label="Tunjangan Transport" size="2" mandatory={false} val={inputTunjanganTransport} onChangeValue={(event) => setInputTunjanganTransport(event.target.value)}/>
                                                            <FormInput type="text" label="Tunjangan Pulsa" size="2" mandatory={false} val={inputTunjanganPulsa} onChangeValue={(event) => setInputTunjanganPulsa(event.target.value)}/>
                                                            {/* <FormInput type="text" label="Tunjangan Kost" size="2" mandatory={false} val={inputTunjanganKost} onChangeValue={(event) => setInputTunjanganKost(event.target.value)}/> */}
                                                            <FormInput type="text" label="Upah Harian" size="2" mandatory={false} val={inputUpahHarian} onChangeValue={(event) => setInputUpahHarian(event.target.value)}/>
                                                            <FormInput type="select" label="Status" size="2" mandatory={true} val={inputStatus} option={dataStatus} defaultSelect={inputStatus} onChangeValue={(val) => setInputStatus(val)}/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Update" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Edit;