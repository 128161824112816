import React from 'react';

function PrivilegeChecklist(props) {
    return (
        <div className="accordion" id="accordionExample" style={{marginTop: 30}}>
        {
            props.data.map(function(item, index) {
                return (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id={"heading_" + index}>
                            <button className="accordion-button fw-medium collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse_" + index} aria-expanded="false" aria-controls="collapse_a">
                            {item.menu_name}
                            </button>
                        </h2>
                        <div id={"collapse_" + index} className="accordion-collapse collapse" aria-labelledby={"heading_" + index} data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="text-muted" style={{marginBottom: -15}}>
                                    <div className="form-check mb-3">
                                        <input className="form-check-input" type="checkbox" value={item.menu_id} name="check_privilege" onChange={(item) => props.onChangeValue(item)} defaultChecked={item.menu_checklist == 1} />
                                        <label className="form-check-label" htmlFor="check_privilege">
                                            ({item.menu_level}) {item.menu_name}
                                        </label>
                                    </div>
                                    {
                                        item.children.map(function(item_children, index_children) {
                                            return (
                                                <>
                                                    <div className="form-check mb-3" style={{marginLeft: 30}}>
                                                        <input className="form-check-input" type="checkbox" value={item_children.menu_id} name="check_privilege" onChange={(item_children) => props.onChangeValue(item_children)} defaultChecked={item_children.menu_checklist == 1} />
                                                        <label className="form-check-label" htmlFor="check_privilege">
                                                            ({item_children.menu_level}) {item_children.menu_name}
                                                        </label>
                                                    </div>
                                                    {
                                                        item_children.children.map(function(item_action, index_action) {
                                                            return (
                                                                <div className="form-check mb-3" style={{marginLeft: 60}}>
                                                                    <input className="form-check-input" type="checkbox" value={item_action.menu_id} name="check_privilege" onChange={(item_action) => props.onChangeValue(item_action)} defaultChecked={item_action.menu_checklist == 1} />
                                                                    <label className="form-check-label" htmlFor={"check_" + item_action.menu_id}>
                                                                        ({item_action.menu_level}) {item_action.menu_name}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    </div>
    )
}

export default PrivilegeChecklist