import './style.css'
function ElementPreloaderList() {
    return (
        <>
            <table border="0" cellPadding="0" cellSpacing="0" style={{width: '100%', marginBottom: 20}}>
                <tr>
                    <td style={{width: '100%', textAlign: 'right'}} className="placeholder-glow">
                        <span style={{height: 35, width: 80, borderRadius: 5}} className="placeholder"></span>
                        <span style={{height: 35, width: 80, borderRadius: 5, marginLeft: 10}} className="placeholder"></span>
                    </td>
                </tr>
            </table>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <table border="0" cellPadding="0" cellSpacing="0" style={{width: '100%'}}>
                                <tr>
                                    <td style={{width: '20%', textAlign: 'center'}} className="placeholder-glow">
                                        <span style={{height: 35, width: 100, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                    <td style={{width: '40%', textAlign: 'center'}} className="placeholder-glow">
                                        <span style={{height: 35, width: 300, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                    <td style={{width: '20%', textAlign: 'center'}} className="placeholder-glow">
                                        <span style={{height: 35, width: 100, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                    <td style={{width: '20%', textAlign: 'center'}} className="placeholder-glow">
                                        <span style={{height: 35, width: 100, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4" style={{borderBottom: '0.5px solid #e9e9ef', paddingTop: 10}}></td>
                                </tr>
                                <tr>
                                    <td style={{width: '20%', textAlign: 'center', paddingTop: 10}} className="placeholder-glow">
                                        <span style={{height: 30, width: 100, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                    <td style={{width: '40%', textAlign: 'center', paddingTop: 10}} className="placeholder-glow">
                                        <span style={{height: 30, width: 300, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                    <td style={{width: '20%', textAlign: 'center', paddingTop: 10}} className="placeholder-glow">
                                        <span style={{height: 30, width: 100, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                    <td style={{width: '20%', textAlign: 'center', paddingTop: 10}} className="placeholder-glow">
                                        <span style={{height: 30, width: 100, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4" style={{borderBottom: '0.5px solid #e9e9ef', paddingTop: 10}}></td>
                                </tr>
                                <tr>
                                    <td style={{width: '20%', textAlign: 'center', paddingTop: 10}} className="placeholder-glow">
                                        <span style={{height: 30, width: 100, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                    <td style={{width: '40%', textAlign: 'center', paddingTop: 10}} className="placeholder-glow">
                                        <span style={{height: 30, width: 300, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                    <td style={{width: '20%', textAlign: 'center', paddingTop: 10}} className="placeholder-glow">
                                        <span style={{height: 30, width: 100, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                    <td style={{width: '20%', textAlign: 'center', paddingTop: 10}} className="placeholder-glow">
                                        <span style={{height: 30, width: 100, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4" style={{borderBottom: '0.5px solid #e9e9ef', paddingTop: 10}}></td>
                                </tr>
                                <tr>
                                    <td style={{width: '20%', textAlign: 'center', paddingTop: 10}} className="placeholder-glow">
                                        <span style={{height: 30, width: 100, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                    <td style={{width: '40%', textAlign: 'center', paddingTop: 10}} className="placeholder-glow">
                                        <span style={{height: 30, width: 300, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                    <td style={{width: '20%', textAlign: 'center', paddingTop: 10}} className="placeholder-glow">
                                        <span style={{height: 30, width: 100, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                    <td style={{width: '20%', textAlign: 'center', paddingTop: 10}} className="placeholder-glow">
                                        <span style={{height: 30, width: 100, borderRadius: 5}} className="placeholder"></span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}

export default ElementPreloaderList;