import React, { useRef, useState, useEffect } from 'react';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles, alpha } from '@material-ui/core/styles';
import MUITextField from '@material-ui/core/TextField';
import APP_HELPERS_COMMON from '../../Helpers/Common';
import { BottomNavigation } from '@material-ui/core';
import CurrencyInput from 'react-currency-input-field';
import { RangeDatePicker, SingleDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import './style.css'

function FormInput(props) {
    const classes = useStyles();

    const defaultSelect = { value: 'strawberry', label: 'Strawberry' };
    const [rangeValue, setRangeValue] = React.useState([null, null]);

    const optionSelect = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    const [selectedDate, setSelectedDate] = useState(props.val);
    const [inputSize, setInputSize] = useState('');
    const [selectedOption, setSelectedOption] = useState({});
    const [selectValue, setSelectValue] = useState({});

    setTimeout(() => {
        setSelectedOption(props.defaultSelect);
    }, 5000)

    const handleDateChange = (date) => {
        let selDate = APP_HELPERS_COMMON.datePickerConvert(date);
        
        setSelectedDate(selDate);

        props.onChangeValue(selDate);
    };
    
    const mandatoryFlag = () => {
        if(props.mandatory) {
            return <span style={{color: 'red'}}>*</span>
        }
    }

    function getSelectValue(data) {
        setSelectValue(data);
        props.onChangeValue(data);
    }

    function handleNumber(event) {
        if(event.target.validity.valid) {
            props.onChangeValue(event);
        }
    }

    function handleCurrency(event) {
        if(event.target.validity.valid) {
            props.onChangeValue(event);
        }
    }

    if(props.type == "text") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <div className="mb-3">
                    <label className="form-label">{props.label} {mandatoryFlag()}</label>
                    <input className="form-control" type="text" placeholder={props.label} value={props.val} onChange={props.onChangeValue} readOnly={props.readonly}/>
                </div>
            </div>
        );
    }

    if(props.type == "upload") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <div className="mb-3">
                    <label className="form-label">{props.label} {mandatoryFlag()}</label>
                    <input className="form-control" type="file" placeholder={props.label} value={props.val} onChange={props.onChangeValue} readOnly={props.readonly}/>
                </div>
            </div>
        );
    }

    if(props.type == "time") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <div className="mb-3">
                    <label className="form-label">{props.label} {mandatoryFlag()}</label>
                    <input className="form-control" type="time" placeholder={props.label} value={props.val} onChange={props.onChangeValue} readOnly={props.readonly}/>
                </div>
            </div>
        );
    }

    if(props.type == "currency") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <div className="mb-3">
                    <label className="form-label">{props.label} {mandatoryFlag()}</label>
                    <CurrencyInput
                        id="validation-example-2-field"
                        placeholder={props.label}
                        allowDecimals={false}
                        className={`form-control`}
                        onValueChange={props.onChangeValue}
                        prefix={''}
                        step={10}
                        allowDecimals={true}
                        defaultValue={props.val}
                        value={props.val}
                        disabled={props.readonly=="readonly"}
                    />
                    {/* <input className="form-control" type="text" placeholder={props.label} value={props.val} onChange={props.onChangeValue} readOnly={props.readonly} /> */}
                </div>
            </div>
        );
    }

    if(props.type == "number") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <div className="mb-3">
                    <label className="form-label">{props.label} {mandatoryFlag()}</label>
                    <input className="form-control" type="number" placeholder={props.label} value={props.val} onChange={props.onChangeValue} readOnly={props.readonly} />
                </div>
            </div>
        );
    }

    // if(props.type == "currency-only") {
    //     return (
    //         <input className="form-control" type="number" placeholder={props.label} value={props.val} onChange={props.onChangeValue} readOnly={props.readonly} />
    //     );
    // }

    if(props.type == "text-search") {
        return (
            <div className="mb-2">
                <label className="form-label" htmlFor="exampleDropdownFormEmail">{props.label}</label>
                <input className="form-control" type="text" placeholder={props.label} value={props.val} onChange={props.onChangeValue} />
            </div>
        );
    }

    if(props.type == "select-search") {
        return (
            <div className="mb-2">
                <label className="form-label" htmlFor="exampleDropdownFormEmail">{props.label}</label>
                <Select options={props.option} cacheOptions={true} value={props.val} onChange={(data) => getSelectValue(data)} />
            </div>
        );
    }
    
    if(props.type == "select") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <div className="mb-3">
                    <label className="form-label">{props.label} {mandatoryFlag()}</label>
                    {
                        (props.readonly == "readonly") ? 
                            <input className="form-control" type="text" placeholder={props.label} value={props.val.label} readOnly={props.readonly}/> 
                        : 
                            <Select options={props.option} cacheOptions={true} value={props.val} onChange={(data) => getSelectValue(data)} />
                    }
                </div>
            </div>
        );
    }

    if(props.type == "select-only") {
        return (
            <Select options={props.option} cacheOptions={true} value={props.val} onChange={(data) => getSelectValue(data)} />
        );
    }

    if(props.type == "date-picker") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <div className="mb-3">
                    <label className="form-label">{props.label} {mandatoryFlag()}</label>
                    <div className={classes.datePickerContainer}>
                        <MUITextField
                            id="date"
                            label=""
                            type="date"
                            defaultValue={props.val}
                            value={props.val}
                            className={classes.datePickerTextField}
                            onChange={(data) => props.onChangeValue(data)}
                            disabled={props.readonly=="readonly"}
                            style={{ backgroundColor: (props.readonly=="readonly") ? '#e9e9ef':"" }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    if(props.type == "date-range-picker") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <div className="mb-3">
                    <label className="form-label">{props.label} {mandatoryFlag()}</label>
                    <div>
                        <RangeDatePicker
                            onChange={props.onChangeValue}
                            // onChange={(startDate, endDate) => props.onChangeValue(startDate, endDate)}
                            startDate={props.valStartDate}
                            endDate={props.valEndDate}
                        />
                    </div>
                </div>
            </div>
        );
    }

    if(props.type == "date-range-single") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <div className="mb-3">
                    <label className="form-label">{props.label} {mandatoryFlag()}</label>
                    <div>
                        <SingleDatePicker
                            onChange={props.onChangeValue}
                            // onChange={(startDate, endDate) => props.onChangeValue(startDate, endDate)}
                            startDate={props.val}
                        />
                    </div>
                </div>
            </div>
        );
    }

    if(props.type == "date-picker-modal") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <div className="mb-3">
                    <label className="form-label">{props.label} {mandatoryFlag()}</label>
                    <div className={classes.datePickerContainer}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label=""
                                format="MM/dd/yyyy"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </div>
        );
    }

    if(props.type == "radio") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <label className="form-label">{props.label} {mandatoryFlag()}</label>
                <div style={{display:"flex", marginTop:"7px"}}>
                {
                    props.option.map(function(item, index) {
                        return (
                                <div className="form-check" style={{marginRight:"15px"}}>
                                    <input className="form-check-input" type="radio" id={props.name+'_'+index} name={props.name} value={item.value} onChange={props.onChangeValue} checked={item.value === props.val} />
                                    <label className="form-check-label" htmlFor={props.name+'_'+index} style={{fontWeight:400}}>
                                        {item.label}
                                    </label>
                                </div>
                        )
                    })
                }
                </div>
            </div>
        );
    }

    if(props.type == "submit") {
        return (
            <div className="mt-4">
                { !props.loading ? 
                    (props.style != null) ?
                        <button type="submit" className={'btn '+ props.style +' waves-effect waves-light'} onClick={props.handleSubmit}>{props.label}</button>
                    :
                        <button type="submit" className="btn btn-primary w-md" onClick={props.handleSubmit}>{props.label}</button>
                :  
                    <button type="submit" className="btn btn-primary w-md" onClick={props.handleSubmit} disabled>
                        <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                    </button>
                }
            </div>
        )
    }

    if(props.type == "button-dismiss") {
        return (
                <button type="submit" className="btn btn-primary w-md" onClick={props.handleSubmit} data-bs-dismiss="offcanvas" aria-label="Close">{props.label}</button>
        )
    }

    

    if(props.type == "submit-search") {
        return (
            <button type="submit" className="btn btn-primary" onClick={props.handleSubmitSearch}>{props.label}</button>
        )
    }

    if(props.type == "button-search-clear") {
        return (
            <button type="button" className="btn btn-light" style={{marginLeft: 10}} onClick={props.handleButtonClear}>{props.label}</button>
        )
    }

    if(props.type == "button-modal") {
        return (
            <div className="mt-4">
                { (props.position == 'right') && <button className="btn btn-success" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={props.handleSetPosition('right')}>{props.label}</button> }
                { (props.position == 'left') && <button className="btn btn-success" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft" aria-controls="offcanvasLeft" onClick={props.handleSetPosition('left')}>{props.label}</button> }
            </div>
        )
    }
    
    if(props.type == "empty-space") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                &nbsp;
            </div>
        );
    }

    if(props.type == "text-only") {
        return (
            <input className="form-control" type="text" style={{ textAlign: (props.align != null) ? props.align : 'left' }} placeholder={props.label} value={props.val} onChange={props.onChangeValue} readOnly={props.readonly} />
        );
    }

    if(props.type == "currency-only") {
        return (
            <CurrencyInput
                id="validation-example-2-field2"
                placeholder={props.label}
                allowDecimals={false}
                className={`form-control currency-align`}
                onValueChange={props.onChangeValue}
                prefix={''}
                step={10}
                allowDecimals={true}
                defaultValue={props.val}
                value={props.val}
                disabled={props.readonly=="readonly"}
            />
        );
    }

    if(props.type == "password") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <div className="mb-3">
                    <label className="form-label">{props.label} {mandatoryFlag()}</label>
                    <input className="form-control" type="password" placeholder={props.label} value={props.val} onChange={props.onChangeValue} readOnly={props.readonly}/>
                </div>
            </div>
        );
    }

    if(props.type == "upload") {
        return (
            <div className={(props.size == 1) ? 'col-md-12' : 'col-md-6'}>
                <div className="mb-3">
                    <label className="form-label">{props.label} {mandatoryFlag()}</label>
                    <input className="form-control" type="file" placeholder={props.label} onChange={props.onChangeValue} readOnly={props.readonly}/>
                </div>
            </div>
        );
    }

    return (
        <></>
    )
}

const useStyles = makeStyles((theme) => ({
    datePickerContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    datePickerTextField: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      width: '100%',
    },
}));

export default FormInput;