import React from 'react';

function PageTablePaggingSimple(props) {
    return (
        <nav aria-label="...">
            { 
            
                props.count > 0 ? 
                    <>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <p>Total data ({props.params.total} records)</p>
                            </div>
                            <div className="col-lg-6">
                                <ul className="pagination" style={{float:'right'}}>
                                    <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => props.handleUrl(props.params.first_page_url)}><i className="mdi mdi-chevron-double-left"></i></a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => props.handleUrl(props.params.prev_page_url)}><i className="mdi mdi-chevron-left"></i></a>
                                    </li>
                                    <li className="page-item">
                                        <p className="page-link">Page {props.params.current_page} from {props.params.last_page}</p>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => props.handleUrl(props.params.next_page_url)}><i className="mdi mdi-chevron-right"></i></a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => props.handleUrl(props.params.last_page_url)}><i className="mdi mdi-chevron-double-right"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                : null
            }
        </nav>
    )
}

export default PageTablePaggingSimple;