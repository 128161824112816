import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FormInput from '../../Components/FormInput';
import PageTabHeader from '../../Components/Page/PageTabHeader';
import PageTitle from '../../Components/Page/PageTitle';
import ModelParameter from '../../Models/Parameter/config';
import ElementAlert from '../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../Components/Element/ElementPreloaderForm';
import APP_HELPERS_COMMON from '../../Helpers/Common';
import APP_STORAGE from '../../Storage';

function Parameter() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
    /* ----------
    Page Vars
    ------------------ */
        const [pageTitle, setPageTitle] = useState("Parameter");
        const [pageID, setPageID] = useState(26);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        const defaultOption = { value: '0', label: ' - Pilih -' };
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputTanggal, setInputTanggal] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputStatusPayroll, setInputStatusPayroll] = useState(defaultOption);
        const [inputMinHariKerja, setInputMinHariKerja] = useState('');
        const [inputMaxTanggalKerja, setInputMaxTanggalKerja] = useState(0);
        const [inputPersenJabatan, setInputPersenJabatan] = useState(0);
        const [inputMaxJabatan, setInputMaxJabatan] = useState(0);
        const [inputMaxGajiBPJS, setInputMaxGajiBPJS] = useState(0);
        const [inputMaxGajiPensiun, setInputMaxGajiPensiun] = useState(0);
        const [inputMaxUsiaPensiun, setInputMaxUsiaPensiun] = useState(0);
        const [inputMinHariTHR, setInputMinHariTHR] = useState(0);

        const [inputBPJSKesPerush, setInputBPJSKesPerush] = useState(0);
        const [inputBPJSKesKary, setInputBPJSKesKary] = useState(0);
        const [inputJHTPerush, setInputJHTPerush] = useState(0);
        const [inputJHTKary, setInputJHTKary] = useState(0);
        const [inputJIPPerush, setInputJIPPerush] = useState(0);
        const [inputJIPKary, setInputJIPKary] = useState(0);
        const [inputJIPDeduc, setInputJIPDeduc] = useState(0);
        const [inputJKK, setInputJKK] = useState(0);
        const [inputJKM, setInputJKM] = useState(0);
        const [inputBPJSTambahan, setInputBPJSTambahan] = useState(0);

        const [inputStatusGrossUp, setInputStatusGrossUp] = useState(defaultOption);
        // -----------------
        const [dataStatusPayroll, setDataStatusPayroll] = useState([defaultOption]);
        const [dataStatusGrossUp, setDataStatusGrossUp] = useState([defaultOption]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let res = await ModelParameter.get();
            
            if(res.status === "401") {
                APP_STORAGE.clearLocalStorage();
                navigate("/login");
            }

            setInputTanggal(res.data.tanggal_payroll_awal);
            setInputStatusPayroll(res.data.status_payroll);
            setInputMinHariKerja(res.data.min_hari_kerja);
            setInputMaxTanggalKerja(res.data.max_tanggal_kerja);
            setInputPersenJabatan(res.data.persen_biaya_jabatan);
            setInputMaxJabatan(res.data.max_biaya_jabatan);
            setInputMaxGajiBPJS(res.data.max_gaji_bpjs);
            setInputMaxGajiPensiun(res.data.max_gaji_pensiun);
            setInputMaxUsiaPensiun(res.data.max_usia_pensiun);
            setInputMinHariTHR(res.data.min_hari_thr);

            setInputBPJSKesPerush(res.data.persen_bpjs_perusahaan);
            setInputBPJSKesKary(res.data.persen_bpjs_karyawan);
            setInputJHTPerush(res.data.persen_jht_perusahaan);
            setInputJHTKary(res.data.persen_jht_karyawan);
            setInputJIPPerush(res.data.persen_jip_perusahaan);
            setInputJIPKary(res.data.persen_jip_karyawan);
            setInputJIPDeduc(res.data.persen_jip_deduction);
            setInputJKK(res.data.persen_jkk);
            setInputJKM(res.data.persen_jkm);
            setInputBPJSTambahan(res.data.persen_bpjs_tambahan);

            setInputStatusGrossUp({ value: res.data.gross_up_status, label: res.data.gross_up_status });

            dataStatusGrossUp.push(...res.collections.ya_tidak);
            dataStatusPayroll.push(...res.collections.open_close);
            
            setIsLoading(false);

            return () => {
                
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }
        
        const handleSubmit = async (e) => {
            e.preventDefault();
            
            setIsLoadingSubmit(true);

            const formSubmit = {
                tanggal_payroll_awal: inputTanggal,
                status_payroll: inputStatusPayroll,
                min_hari_kerja: inputMinHariKerja,
                max_tanggal_kerja: inputMaxTanggalKerja,
                persen_biaya_jabatan: inputPersenJabatan,
                max_biaya_jabatan: inputMaxJabatan,
                max_gaji_bpjs: inputMaxGajiBPJS,
                max_gaji_pensiun: inputMaxGajiPensiun,
                max_usia_pensiun: inputMaxUsiaPensiun,
                min_hari_thr: inputMinHariTHR,
                gross_up_status: inputStatusGrossUp.value,

                persen_bpjs_perusahaan: inputBPJSKesPerush,
                persen_bpjs_karyawan: inputBPJSKesKary,
                persen_jht_perusahaan: inputJHTPerush,
                persen_jht_karyawan: inputJHTKary,
                persen_jip_perusahaan: inputJIPPerush,
                persen_jip_karyawan: inputJIPKary,
                persen_jip_deduction: inputJIPPerush,
                persen_jkk: inputJKK,
                persen_jkm: inputJKM,
                persen_bpjs_tambahan: inputBPJSTambahan,
            }
            
            let res = await ModelParameter.update(formSubmit);

            if(res.status === '100') {
                navigate("/parameter/index");

                setIsLoadingSubmit(false);
                setAlertOpen(true);
                setAlertMessage("Success");
                setAlertStyle("success");
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="date-picker" label="Tanggal Payroll" size="2" mandatory={true} val={inputTanggal} onChangeValue={(event) => setInputTanggal(event.target.value)}/>
                                                            <FormInput type="text" label="Status Payroll" size="2" mandatory={true} readonly={true} val={inputStatusPayroll} onChangeValue={(event) => setInputStatusPayroll(event.target.value)}/>
                                                            {/* <FormInput type="text" label="Minimal Hari Kerja (Pending)" size="2" mandatory={true} val={inputMinHariKerja} onChangeValue={(event) => setInputMinHariKerja(event.target.value)}/> */}
                                                            <FormInput type="text" label="Maximal Tanggal Masuk (Pending)" size="2" mandatory={true} val={inputMaxTanggalKerja} onChangeValue={(event) => setInputMaxTanggalKerja(event.target.value)}/>
                                                            <FormInput type="currency" label="% Biaya Jabatan" size="2" mandatory={true} val={inputPersenJabatan} onChangeValue={(value) => setInputPersenJabatan(value)}/>
                                                            <FormInput type="currency" label="Maximal Biaya Jabatan" size="2" mandatory={true} val={inputMaxJabatan} onChangeValue={(value) => setInputMaxJabatan(value)}/>
                                                            <FormInput type="currency" label="Maximal Gaji BPJS" size="2" mandatory={true} val={inputMaxGajiBPJS} onChangeValue={(value) => setInputMaxGajiBPJS(value)}/>
                                                            <FormInput type="currency" label="Maximal Gaji Pensiun" size="2" mandatory={true} val={inputMaxGajiPensiun} onChangeValue={(value) => setInputMaxGajiPensiun(value)}/>
                                                            <FormInput type="currency" label="Maximal Usia Pensiun" size="2" mandatory={true} val={inputMaxUsiaPensiun} onChangeValue={(value) => setInputMaxUsiaPensiun(value)}/>
                                                            <FormInput type="select" label="Status Gross Up" size="2" mandatory={true} val={inputStatusGrossUp} option={dataStatusGrossUp} defaultSelect={inputStatusGrossUp} onChangeValue={(event) => setInputStatusGrossUp(event)}/>
                                                            <FormInput type="text" label="Minimal Hari THR" size="2" mandatory={true} val={inputMinHariTHR} onChangeValue={(event) => setInputMinHariTHR(event.target.value)}/>
                                                            <FormInput type="text" label="Tarif BPJKS Kes (Perusahaan)" size="2" mandatory={true} val={inputBPJSKesPerush} onChangeValue={(event) => setInputBPJSKesPerush(event.target.value)}/>
                                                            <FormInput type="text" label="Tarif BPJKS Kes (Karyawan)" size="2" mandatory={true} val={inputBPJSKesKary} onChangeValue={(event) => setInputBPJSKesKary(event.target.value)}/>
                                                            <FormInput type="text" label="Tarif JHT (Perusahaan)" size="2" mandatory={true} val={inputJHTPerush} onChangeValue={(event) => setInputJHTPerush(event.target.value)}/>
                                                            <FormInput type="text" label="Tarif JHT (Karyawan)" size="2" mandatory={true} val={inputJHTKary} onChangeValue={(event) => setInputJHTKary(event.target.value)}/>
                                                            <FormInput type="text" label="Tarif JIP (Perusahaan)" size="2" mandatory={true} val={inputJIPPerush} onChangeValue={(event) => setInputJIPPerush(event.target.value)}/>
                                                            <FormInput type="text" label="Tarif JIP (Karyawan)" size="2" mandatory={true} val={inputJIPKary} onChangeValue={(event) => setInputJIPKary(event.target.value)}/>
                                                            <FormInput type="text" label="Tarif JKK" size="2" mandatory={true} val={inputJKK} onChangeValue={(event) => setInputJKK(event.target.value)}/>
                                                            <FormInput type="text" label="Tarif JKM" size="2" mandatory={true} val={inputJKM} onChangeValue={(event) => setInputJKM(event.target.value)}/>
                                                            <FormInput type="text" label="Tarif BPJS Tambahan" size="2" mandatory={true} val={inputBPJSTambahan} onChangeValue={(event) => setInputBPJSTambahan(event.target.value)}/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Simpan" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Parameter;