import { Navigate, Outlet, useState } from "react-router-dom";
import Sidebar from "../Components/Layout/Sidebar";
import Header from "../Components/Layout/Header";
import PageFooter from "../Components/Page/PageFooter";
import APP_STORAGE from "../Storage";

const useAuth = () => {
  const user = { loggedIn: APP_STORAGE.getAuthStatus() };
  
  return user && user.loggedIn;
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();

  return (
    <>
        {isAuth
            ? 
                <>
                    <div id="layout-wrapper">
                        <Header />
                        <Sidebar />
                        <div className="main-content">
                            <Outlet />
                            <PageFooter />
                        </div>
                    </div>
                    <div className="rightbar-overlay"></div>
                </>
            : 
            <Navigate to="/login" />
        }
    </>
  );
};

export default ProtectedRoutes;