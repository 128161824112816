import React, { useEffect, useState } from 'react';
// import Header from '../../../Components/Layout/Header';
import PageTitle from '../../../Components/Page/PageTitle';
import { Link, useParams, useNavigate } from 'react-router-dom';
import APP_STORAGE from '../../../Storage';
import APP_MODEL_USERS from '../../../Models/UserManagement/users';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import CommonEndpoint from '../../../Models/common';
import FormInput from '../../../Components/FormInput';
import ElementPreloaderList from '../../../Components/Element/ElementPreloaderList';
import PageActionDirect from '../../../Components/Page/PageActionDirect';
import PageTable from '../../../Components/PageTable';
import ElementAlert from '../../../Components/Element/ElementAlert';

function Users(props) {
    /* ----------
    URL Params
    ------------------ */
    let navigate        = useNavigate();
    let urlAction       = useParams();
    let urlID           = urlAction.id;
    /* ----------
    Page Vars
    ------------------ */
    const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
    const [pageTitle, setPageTitle] = useState("Users");
    const [actionMenu, setActionMenu] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // -------------------
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false);
    const [alertStyle, setAlertStyle] = useState("");
    // -------------------
    const [tableData, setTableData] = useState([]);
    const [tableParams, setTableParams] = useState([]);
    const [tablePagging, setTablePagging] = useState([]);
    // -------------------
    const [searchNama, setSearchNama] = useState('');
    // -------------------
    const rejectEditStatus = [2];

    /* ----------
    Page Data
    ------------------ */
    useEffect(async () => {
        let search = {
            nama: searchNama,
        };

        let userMatrix = APP_STORAGE.getUserMatrix();
        let data = await APP_MODEL_USERS.getAll(search);
        
        if(data.status === "401") {
            APP_STORAGE.clearLocalStorage();
            navigate("/login");
        }
        
        setActionMenu(userMatrix.filter(item => item.menu_parent == dataID));
        refreshTable(data);
        setIsLoading(false);
        
        return () => {
            
        };
    }, []);

    const DataTables = {
        header: [
            { label: 'User Id', align: 'center', width: '10%' },
            { label: 'Nama', align: 'center', width: '25%' },
            { label: 'Group', align: 'center', width: '20%' },
            { label: 'Cabang', align: 'center', width: '20%' },
            { label: 'Status', align: 'center', width: '15%' },
            { label: 'Action', align: 'center', width: '10%' },
        ],
        data: [
            { field: 'user_id', align: 'center', type: 'text' },
            { field: 'name', align: 'center', type: 'text' },
            { field: 'nama_group', align: 'center', type: 'text' },
            { field: 'nama_cabang', align: 'center', type: 'text' },
            { field: 'keterangan_status', align: 'center', type: 'badge' },
            { field: actionMenu, align: 'center', type: 'action' },
        ]  
    }
        
    /* ----------
    Methods
    ------------------ */
    function handleActionMenu(data) {
        let validate = rejectEditStatus.includes(data.status);
        
        data.event.preventDefault();

        if(validate) {
            setAlertMessage("Proses tidak bisa dilakukan");
            setAlertOpen(true);
            setAlertStyle("error");
        } else {
            navigate(data.url);
        }
    }

    async function handleUrl(url) {
        let search = {
            nama: searchNama
        };

        let data = await CommonEndpoint.get_pagging(url, search);

        refreshTable(data);
    }

    async function handleSubmitSearch(e) {
        e.preventDefault();

        let search = {
            nama: searchNama
        };
        
        let data = await APP_MODEL_USERS.getAll(search);
        
        refreshTable(data);
    }

    function refreshTable(data) {
        setTableData(data.data.data);
        setTableParams(data.data);
        setTablePagging(data.data.links);
    }

    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                {
                    (isLoading) ? 
                        <ElementPreloaderList />
                    :
                    <>
                        <div className="row mb-3">
                            <div className="col-lg-12" style={{textAlign:'right'}}>
                                <PageActionDirect actionMenu={actionMenu} handleActionMenu={(data) => handleActionMenu(data)} />
                                <div className="btn-group">
                                    <button type="button" className="btn btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Search <i className="mdi mdi-chevron-down"></i>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-md p-4" style={{ width: 350 }}>
                                        <FormInput type="text-search" label="Nama" val={searchNama} onChangeValue={(event) => setSearchNama(event.target.value)}/>
                                        <FormInput type="submit-search" label="Search" handleSubmitSearch={(event) => handleSubmitSearch(event)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <PageTable layout={DataTables} item={tableData} count={tableData.length} params={tableParams} pagging={tablePagging} handleUrl={handleUrl} handleActionMenu={(data) => handleActionMenu(data)}/>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </>
                }
            </div>

            <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
        </div>
    )
}

export default Users;