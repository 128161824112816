import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import PageTitle from '../../../Components/Page/PageTitle';
import APP_MODEL_PENGGAJIAN from '../../../Models/Payroll/penggajian';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';

function Cancel() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
    /* ----------
    Page Vars
    ------------------ */
        const [pageTitle, setPageTitle] = useState("Pembatalan");
        const [pageID, setPageID] = useState(100);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputTanggalAwal, setInputTanggalAwal] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputTanggalAkhir, setInputTanggalAkhir] = useState(APP_HELPERS_COMMON.getCurrentDate());
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let resPenggajian  = await APP_MODEL_PENGGAJIAN.get_current();
            
            setInputTanggalAwal(resPenggajian.data.tanggal_payroll_awal);
            setInputTanggalAkhir(resPenggajian.data.tanggal_payroll_akhir);

            setIsLoading(false);

            return () => {
                
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }
        
        const handleSubmit = async (e) => {
            e.preventDefault();
            
            setIsLoadingSubmit(true);

            const formSubmit = {
                tanggal_awal: inputTanggalAwal,
                tanggal_akhir: inputTanggalAkhir,
            }
           
            let res = await APP_MODEL_PENGGAJIAN.cancel(formSubmit);

            if(res.status == '100') {
                navigate("/penggajian/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="date-picker" label="Tanggal Awal" size="2" mandatory={true} readonly="readonly" val={inputTanggalAwal} onChangeValue={(event) => setInputTanggalAwal(event.target.value)}/>
                                                            <FormInput type="date-picker" label="Tanggal Akhir" size="2" mandatory={true} readonly="readonly" val={inputTanggalAkhir} onChangeValue={(event) => setInputTanggalAkhir(event.target.value)}/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Batalkan" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cancel;