import React from 'react';
import { Link } from 'react-router-dom';
import APP_HELPERS_COMMON from '../../../Helpers/Common';

function SingleMenu(props) {
    if(props.item.menu_level == 1) {
        return (
            <Link to={props.item.menu_url}><i className={props.item.menu_icon} style={{ width: 30  }}></i> {props.item.menu_name}</Link>
        );
    } else {
        return (
            // <Link to={props.item.menu_url}><i className={props.item.menu_icon} style={{ marginRight: 10  }}></i> {props.item.menu_name}</Link>
            <Link to={props.item.menu_url + '/' + APP_HELPERS_COMMON.crypt(props.item.menu_id.toString())}><i className={props.item.menu_icon} style={{ marginLeft: 25  }}></i> {props.item.menu_name}</Link>
        )
    }

    return (
        <>
            
        </>
    )
}

export default SingleMenu;