import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FormInput from '../../Components/FormInput';
import PageTabHeader from '../../Components/Page/PageTabHeader';
import PageTitle from '../../Components/Page/PageTitle';
import ElementAlert from '../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../Components/Element/ElementPreloaderForm';
import APP_MODEL_SYNC from '../../Models/Sync/sync';


function Sinkronisasi() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
    /* ----------
    Page Vars
    ------------------ */
        const [pageTitle, setPageTitle] = useState("Sinkronisasi");
        const [pageID, setPageID] = useState(129);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        const defaultOption = { value: '0', label: ' - Pilih -' };
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -------------------
        const [isJabatan, setIsJabatan] = useState("");
        const [isDepartemen, setIsDepartemen] = useState("");
        const [isCabang, setIsCabang] = useState("");
        const [isHoliday, setIsHoliday] = useState("");
        const [isLevel, setIsLevel] = useState("");
        const [isKaryawan, setIsKaryawan] = useState("");
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            setIsLoading(false);

            return () => {
                
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }

        const renderSubmit = () => {
            return <div className="row">
                        <div className="col-md-12" style={{textAlign: 'left'}}>
                            <FormInput type="submit" label="Sync All" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                        </div>
                    </div>
        }
        
        const handleSubmit = async (e) => {
            e.preventDefault();
            
            setIsLoadingSubmit(true);
            
            syncJabatan();
            syncDepartemen();
            syncLevel();
            syncCabang();
            syncHoliday();
            syncKaryawan();
        }

        const syncJabatan = async () => {
            setIsJabatan("Progress ...");

            let res = await APP_MODEL_SYNC.sync_jabatan();

            if(res.status == '100') {
                setIsJabatan("Success");
            } else {
                setIsJabatan("Failed");
            }
        }

        const syncDepartemen = async () => {
            setIsDepartemen("Progress ...");

            let res = await APP_MODEL_SYNC.sync_departemen();

            if(res.status == '100') {
                setIsDepartemen("Success");
            } else {
                setIsDepartemen("Failed");
            }
        }

        const syncLevel = async () => {
            setIsLevel("Progress ...");

            let res = await APP_MODEL_SYNC.sync_level();

            if(res.status == '100') {
                setIsLevel("Success");
            } else {
                setIsLevel("Failed");
            }
        }

        const syncCabang = async () => {
            setIsCabang("Progress ...");

            let res = await APP_MODEL_SYNC.sync_cabang();

            if(res.status == '100') {
                setIsCabang("Success");
            } else {
                setIsCabang("Failed");
            }
        }

        const syncHoliday = async () => {
            setIsHoliday("Progress ...");

            let res = await APP_MODEL_SYNC.sync_holiday();

            if(res.status == '100') {
                setIsHoliday("Success");
            } else {
                setIsHoliday("Failed");
            }
        }

        const syncKaryawan = async () => {
            setIsKaryawan("Progress ...");

            let res = await APP_MODEL_SYNC.sync_karyawan();

            if(res.status == '100') {
                setIsKaryawan("Success");
            } else {
                setIsKaryawan("Failed");
            }

            setIsLoadingSubmit(false);
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>  
                                                        <div className="table-responsive">
                                                            <table className="table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{textAlign: 'center'}}>Data</th>
                                                                        <th style={{textAlign: 'center'}}>Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Tabel Jabatan</td>
                                                                        <td style={{textAlign: 'center'}}>
                                                                            { (isJabatan == "Failed") ? <span className="badge rounded-pill bg-danger" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isJabatan}</span> : null }
                                                                            { (isJabatan == "Progress ...") ? <span className="badge rounded-pill bg-warning" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isJabatan}</span> : null }
                                                                            { (isJabatan == "Success") ? <span className="badge rounded-pill bg-success" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isJabatan}</span> : null }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Tabel Departemen</td>
                                                                        <td style={{textAlign: 'center'}}>
                                                                            { (isDepartemen == "Failed") ? <span className="badge rounded-pill bg-danger" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isDepartemen}</span> : null }
                                                                            { (isDepartemen == "Progress ...") ? <span className="badge rounded-pill bg-warning" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isDepartemen}</span> : null }
                                                                            { (isDepartemen == "Success") ? <span className="badge rounded-pill bg-success" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isDepartemen}</span> : null }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Tabel Cabang</td>
                                                                        <td style={{textAlign: 'center'}}>
                                                                            { (isCabang == "Failed") ? <span className="badge rounded-pill bg-danger" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isCabang}</span> : null }
                                                                            { (isCabang == "Progress ...") ? <span className="badge rounded-pill bg-warning" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isCabang}</span> : null }
                                                                            { (isCabang == "Success") ? <span className="badge rounded-pill bg-success" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isCabang}</span> : null }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Tabel Holiday</td>
                                                                        <td style={{textAlign: 'center'}}>
                                                                            { (isHoliday == "Failed") ? <span className="badge rounded-pill bg-danger" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isHoliday}</span> : null }
                                                                            { (isHoliday == "Progress ...") ? <span className="badge rounded-pill bg-warning" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isHoliday}</span> : null }
                                                                            { (isHoliday == "Success") ? <span className="badge rounded-pill bg-success" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isHoliday}</span> : null }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Tabel Level</td>
                                                                        <td style={{textAlign: 'center'}}>
                                                                            { (isLevel == "Failed") ? <span className="badge rounded-pill bg-danger" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isLevel}</span> : null }
                                                                            { (isLevel == "Progress ...") ? <span className="badge rounded-pill bg-warning" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isLevel}</span> : null }
                                                                            { (isLevel == "Success") ? <span className="badge rounded-pill bg-success" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isLevel}</span> : null }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Tabel Karyawan</td>
                                                                        <td style={{textAlign: 'center'}}>
                                                                            { (isKaryawan == "Failed") ? <span className="badge rounded-pill bg-danger" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isKaryawan}</span> : null }
                                                                            { (isKaryawan == "Progress ...") ? <span className="badge rounded-pill bg-warning" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isKaryawan}</span> : null }
                                                                            { (isKaryawan == "Success") ? <span className="badge rounded-pill bg-success" style={{paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8}}>{isKaryawan}</span> : null }
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                            {renderSubmit()}
                                                            {/* <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Sync All" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div> */}
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sinkronisasi;