import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import PageTitle from '../../../Components/Page/PageTitle';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import APP_MODEL_KARYAWAN from '../../../Models/Karyawan/datakaryawan';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';

function Detail() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
        let urlAction       = useParams();
        let urlID           = urlAction.id;
    /* ----------
    Page Vars
    ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Detail Karyawan");
        const [pageID, setPageID] = useState(13);
        const [tabs, setTabs] = useState(['Data Pribadi', 'Data Karyawan']);
        const [activeTab, setActiveTab] = useState('Data Pribadi');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        const defaultOption = { value: '0', label: ' - Pilih -' };
        /* ----------
        Data Pribadi
        -------------------- */
        const [inputID, setInputID] = useState('');
        const [inputNama, setInputNama] = useState('');
        const [inputHP, setInputHP] = useState('');
        const [inputTelp, setInputTelp] = useState('');
        const [inputKotaKelahiran, setInputKotaKelahiran] = useState('');
        const [inputTanggalLahir, setInputTanggalLahir] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputJenisKelamin, setInputJenisKelamin] = useState(defaultOption);
        const [inputAgama, setInputAgama] = useState(defaultOption);
        const [inputStatusNikah, setInputStatusNikah] = useState(defaultOption);
        const [inputGolonganDarah, setInputGolonganDarah] = useState(defaultOption);
        const [inputJenisIdentitas, setInputJenisIdentitas] = useState(defaultOption);
        const [inputNoIdentitas, setInputNoIdentitas] = useState('');
        const [inputTanggalIdentitas, setInputTanggalIdentitas] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputAlamat, setInputAlamat] = useState('');
        const [inputKodePos, setInputKodePos] = useState('');
        const [inputEmail, setInputEmail] = useState('');
        /* ----------
        Data Karyawan
        -------------------- */
        const [inputNik, setInputNik] = useState('');
        const [inputDepartemen, setInputDepartemen] = useState(defaultOption);
        const [inputJabatan, setInputJabatan] = useState(defaultOption);
        const [inputLevel, setInputLevel] = useState(defaultOption);
        const [inputCabang, setInputCabang] = useState(defaultOption);
        const [inputStatusKaryawan, setInputStatusKaryawan] = useState(defaultOption);
        const [inputTanggalMasuk, setInputTanggalMasuk] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputTanggalBerakhir, setInputTanggalBerakhir] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputDisplayTanggalBerakhir, setInputDisplayTanggalBerakhir] = useState(false);
        const [firstState, setFirstState] = useState(true);
        /* ----------
        Data Payroll
        -------------------- */
        // const [inputNoBPJSKetenagakerjaan, setInputNoBPJSKetenagakerjaan] = useState('');
        // const [inputTanggalBPJSKetenagakerjaan, setInputTanggalBPJSKetenagakerjaan] = useState(APP_HELPERS_COMMON.getCurrentDate());
        // const [inputNoBPJSKesehatan, setInputNoBPJSKesehatan] = useState('');
        // const [inputTanggalBPJSKesehatan, setInputTanggalBPJSKesehatan] = useState(APP_HELPERS_COMMON.getCurrentDate());
        // const [inputBank, setInputBank] = useState(defaultOption);
        // const [inputNoRekening, setInputNoRekening] = useState('');
        // const [inputAtasNama, setInputAtasNama] = useState('');
        // const [inputNoNPWP, setInputNoNPWP] = useState('');
        // const [inputStatusPTKP, setInputStatusPTKP] = useState(defaultOption);
        // const [inputMetodePajak, setInputMetodePajak] = useState(defaultOption);
        // const [inputGajiPokok, setInputGajiPokok] = useState(0);
        // const [inputGajiBPJS, setInputGajiBPJS] = useState(0);
        /* ----------
        Collection
        -------------------- */
        const [dataJenisKelamin, setDataJenisKelamin] = useState([]);
        const [dataAgama, setDataAgama] = useState([]);
        const [dataStatusNikah, setDataStatusNikah] = useState([]);
        const [dataGolonganDarah, setDataGolonganDarah] = useState([]);
        const [dataJenisIdentitas, setDataJenisIdentitas] = useState([]);
        const [dataDepartemen, setDataDepartemen] = useState([]);
        const [dataJabatan, setDataJabatan] = useState([]);
        const [dataLevel, setDataLevel] = useState([]);
        const [dataCabang, setDataCabang] = useState([]);
        const [dataStatusKaryawan, setDataStatusKaryawan] = useState([]);
        // const [dataBank, setDataBank] = useState([]);
        // const [dataStatusPTKP, setDataStatusPTKP] = useState([]);
        // const [dataMetodePajak, setDataMetodePajak] = useState([]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let resKaryawan  = await APP_MODEL_KARYAWAN.get(dataID);
            /* ----------
            Data Pribadi
            -------------------- */
            setInputID(resKaryawan.data.id);
            setInputNama(resKaryawan.data.nama_karyawan);
            setInputHP(resKaryawan.data.no_hp);
            setInputTelp(resKaryawan.data.no_telp);
            setInputKotaKelahiran(resKaryawan.data.tempat_lahir);
            setInputTanggalLahir(resKaryawan.data.tanggal_lahir);
            setInputJenisKelamin({ value: resKaryawan.data.jenis_kelamin_id, label: resKaryawan.data.ket_jenis_kelamin });
            setInputAgama({ value: resKaryawan.data.agama_id, label: resKaryawan.data.ket_agama });
            setInputStatusNikah({ value: resKaryawan.data.status_nikah_id, label: resKaryawan.data.ket_status_nikah });
            setInputGolonganDarah({ value: resKaryawan.data.golongan_darah, label: resKaryawan.data.ket_golongan_darah });
            setInputJenisIdentitas({ value: resKaryawan.data.identitas_id, label: resKaryawan.data.ket_jenis_identitas });
            setInputNoIdentitas(resKaryawan.data.no_identitas);
            setInputTanggalIdentitas(resKaryawan.data.tanggal_berlaku_identitas);
            setInputAlamat(resKaryawan.data.alamat);
            setInputKodePos(resKaryawan.data.kode_pos);
            setInputEmail(resKaryawan.data.email);
            /* ----------
            Data Karyawan
            -------------------- */
            setInputNik(resKaryawan.data.nik);
            setInputDepartemen({ value: resKaryawan.data.departemen_id, label: resKaryawan.data.nama_departemen });
            setInputJabatan({ value: resKaryawan.data.jabatan_id, label: resKaryawan.data.nama_jabatan });
            setInputLevel({ value: resKaryawan.data.level_id, label: resKaryawan.data.ket_level });
            setInputCabang({ value: resKaryawan.data.cabang_id, label: resKaryawan.data.nama_cabang });
            setInputTanggalMasuk(resKaryawan.data.tanggal_masuk);
            setInputTanggalBerakhir(resKaryawan.data.tanggal_berakhir);
            /* ----------
            Data Payroll
            -------------------- */
            // setInputNoBPJSKetenagakerjaan(resKaryawan.data.no_bpjs_ketenagakerjaan);
            // setInputTanggalBPJSKetenagakerjaan(resKaryawan.data.tanggal_bpjs_ketenagakerjaan);
            // setInputNoBPJSKesehatan(resKaryawan.data.no_bpjs_kesehatan);
            // setInputTanggalBPJSKesehatan(resKaryawan.data.tanggal_bpjs_kesehatan);
            // setInputBank({ value: resKaryawan.data.bank_id, label: resKaryawan.data.nama_bank });
            // setInputNoRekening(resKaryawan.data.no_rekening_bank);
            // setInputAtasNama(resKaryawan.data.atas_nama_rekening);
            // setInputNoNPWP(resKaryawan.data.no_npwp);
            // setInputStatusPTKP({ value: resKaryawan.data.status_ptkp_id, label: resKaryawan.data.ket_status_ptkp });
            // setInputMetodePajak({ value: resKaryawan.data.metode_tax_id, label: resKaryawan.data.ket_metode_pajak });
            // setInputGajiPokok(resKaryawan.data.gaji_pokok);
            // setInputGajiBPJS(resKaryawan.data.gaji_bpjs);
            /* ----------
            Collection
            -------------------- */
            dataJenisKelamin.push(...resKaryawan.collections.jenis_kelamin);
            dataAgama.push(...resKaryawan.collections.agama);
            dataStatusNikah.push(...resKaryawan.collections.status_pernikahan);
            dataGolonganDarah.push(...resKaryawan.collections.golongan_darah);
            dataJenisIdentitas.push(...resKaryawan.collections.jenis_identitas);
            dataLevel.push(...resKaryawan.collections.level);
            dataJabatan.push(...resKaryawan.collections.jabatan);
            dataDepartemen.push(...resKaryawan.collections.departemen);
            dataCabang.push(...resKaryawan.collections.cabang);
            
            if(firstState) {
                setInputStatusKaryawan({ value: resKaryawan.data.status_karyawan_id, label: resKaryawan.data.ket_status_karyawan });
                dataStatusKaryawan.push(...resKaryawan.collections.status_karyawan);
            }

            // dataStatusPTKP.push(...resKaryawan.collections.status_ptkp);
            // dataMetodePajak.push(...resKaryawan.collections.metode_pajak);
            // dataBank.push(...resKaryawan.collections.bank);

            setIsLoading(false);
            setFirstState(false);
            
            if((resKaryawan.data.status_karyawan_id != 0) && (resKaryawan.data.status_karyawan_id != 1)) {
                setInputDisplayTanggalBerakhir(true);
            } else {
                setInputDisplayTanggalBerakhir(false);
            }
            
            return () => {
                setDataJenisKelamin([]);
                setDataAgama([]);
                setDataStatusNikah([]);
                setDataGolonganDarah([]);
                setDataJenisIdentitas([]);
                setDataDepartemen([]);
                setDataJabatan([]);
                setDataLevel([]);
                setDataCabang([]);
                setDataStatusKaryawan([]);
                // setDataBank([]);
                // setDataStatusPTKP([]);
                // setDataMetodePajak([]);
            };
        }, []);

    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} anotherDisable={false} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
        const renderSubmit = () => {
            return <div className="row">
                        <div className="col-md-12" style={{textAlign: 'left'}}>
                            <FormInput type="submit" label="Simpan" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                        </div>
                    </div>
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }

        function handleInputStatusKaryawan(e) {
            setInputStatusKaryawan(e);
            
            if(e.value == 1) {
                setInputDisplayTanggalBerakhir(false);
            } else {
                setInputDisplayTanggalBerakhir(true);
            }
        }

        const handleSubmit = async (e) => {
            e.preventDefault();
            
            setIsLoadingSubmit(true);

            const formSubmit = {
                karyawan_id: inputID,
                nama_karyawan: inputNama,
                email: inputEmail,
                no_hp: inputHP,
                no_telp: inputTelp,
                tempat_lahir: inputKotaKelahiran,
                tanggal_lahir: inputTanggalLahir,
                jenis_kelamin_id: inputJenisKelamin.value,
                agama_id: inputAgama.value,
                status_nikah_id: inputStatusNikah.value,
                golongan_darah: inputGolonganDarah.value,
                identitas_id: inputJenisIdentitas.value,
                no_identitas: inputNoIdentitas,
                tanggal_berlaku_identitas: inputTanggalIdentitas,
                alamat: inputAlamat,
                kode_pos: inputKodePos,
                // -----------------------
                nik: inputNik,
                tanggal_masuk: inputTanggalMasuk,
                // tanggal_berakhir: inputTanggalBerakhir,
                departemen_id: inputDepartemen.value,
                jabatan_id: inputJabatan.value,
                level_id: inputLevel.value,
                cabang_id: inputCabang.value,
                status_karyawan_id: inputStatusKaryawan.value,
                // -----------------------
                // no_bpjs_ketenagakerjaan: inputNoBPJSKetenagakerjaan,
                // tanggal_bpjs_ketenagakerjaan: inputTanggalBPJSKetenagakerjaan,
                // no_bpjs_kesehatan: inputNoBPJSKesehatan,
                // tanggal_bpjs_kesehatan: inputTanggalBPJSKesehatan,
                // bank_id: inputBank.value,
                // no_rekening_bank: inputNoRekening,
                // atas_nama_rekening: inputAtasNama,
                // no_npwp: inputNoNPWP,
                // status_ptkp_id: inputStatusPTKP.value,
                // metode_tax_id: inputMetodePajak.value,
                // gaji_pokok: inputGajiPokok,
                // gaji_bpjs: inputGajiBPJS,
            }
            
            let res = await APP_MODEL_KARYAWAN.update(formSubmit);

            if(res.status == '100') {
                if(activeTab == 'Data Pribadi') {
                    setIsLoadingSubmit(false);
                    setActiveTab('Data Karyawan')
                } else if(activeTab == 'Data Karyawan') {
                    navigate("/datakaryawan/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
                //     setIsLoadingSubmit(false);
                //     setActiveTab('Data Payroll')
                // } else if(activeTab == 'Data Payroll') {
                //     navigate("/datakaryawan/index/" + pageID);
                }
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{activeTab}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {(() => {
                                                if(isLoading) {
                                                    return <ElementPreloaderForm />
                                                } else {
                                                    if(activeTab == 'Data Pribadi') {
                                                        return (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <h4 className="card-title">Informasi Pribadi</h4>
                                                                        <hr />
                                                                    </div>
                                                                    <FormInput type="text" label="Nama Lengkap" size="2" mandatory={true} val={inputNama} readonly="readonly" onChangeValue={(event) => setInputNama(event.target.value)}/>
                                                                    <FormInput type="text" label="Email" size="2" mandatory={true} val={inputEmail} readonly="readonly" onChangeValue={(event) => setInputEmail(event.target.value)}/>
                                                                    <FormInput type="text" label="No HP" size="2" mandatory={true} val={inputHP} readonly="readonly" onChangeValue={(event) => setInputHP(event.target.value)}/>
                                                                    <FormInput type="text" label="No Telp" size="2" mandatory={true} val={inputTelp} readonly="readonly" onChangeValue={(event) => setInputTelp(event.target.value)}/>
                                                                    <FormInput type="text" label="Kota Kelahiran" size="2" mandatory={true} val={inputKotaKelahiran} readonly="readonly" onChangeValue={(event) => setInputKotaKelahiran(event.target.value)}/>
                                                                    <FormInput type="date-picker" label="Tanggal Lahir" size="2" mandatory={true} val={inputTanggalLahir} readonly="readonly" onChangeValue={(event) => setInputTanggalLahir(event.target.value)}/>
                                                                    <FormInput type="select" label="Jenis Kelamin" size="2" mandatory={true} val={inputJenisKelamin} readonly="readonly" option={dataJenisKelamin} defaultSelect={inputJenisKelamin} onChangeValue={(event) => setInputJenisKelamin(event)}/>
                                                                    <FormInput type="select" label="Status Pernikahan" size="2" mandatory={true} val={inputStatusNikah} readonly="readonly" option={dataStatusNikah} onChangeValue={(event) => setInputStatusNikah(event)}/>
                                                                    <FormInput type="select" label="Agama" size="2" mandatory={true} val={inputAgama} option={dataAgama} readonly="readonly" onChangeValue={(event) => setInputAgama(event)}/>
                                                                    <FormInput type="select" label="Golongan Darah" size="2" mandatory={true} val={inputGolonganDarah} readonly="readonly" option={dataGolonganDarah} onChangeValue={(event) => setInputGolonganDarah(event)}/>
                                                                    <div className="col-md-12" style={{marginTop: 20}}>
                                                                        <h4 className="card-title">Informasi Identitas & Alamat</h4>
                                                                        <hr />
                                                                    </div>
                                                                    <FormInput type="select" label="Jenis Identitas" size="2" mandatory={true} val={inputJenisIdentitas} readonly="readonly" option={dataJenisIdentitas} onChangeValue={(event) => setInputJenisIdentitas(event)}/>
                                                                    <FormInput type="text" label="No Identitas" size="2" mandatory={true} val={inputNoIdentitas} readonly="readonly" onChangeValue={(event) => setInputNoIdentitas(event.target.value)}/>
                                                                    <FormInput type="date-picker" label="Masa Berlaku Identitas" size="2" mandatory={true} val={inputTanggalIdentitas} readonly="readonly" onChangeValue={(event) => setInputTanggalIdentitas(event.target.value)}/>
                                                                    <FormInput type="text" label="Alamat" size="2" mandatory={true} val={inputAlamat} readonly="readonly" onChangeValue={(event) => setInputAlamat(event.target.value)}/>
                                                                    <FormInput type="text" label="Kode Pos" size="2" mandatory={true} val={inputKodePos} readonly="readonly" onChangeValue={(event) => setInputKodePos(event.target.value)}/>
                                                                </div>
                                                            </>
                                                        )
                                                    } else if(activeTab == 'Data Karyawan') {
                                                        return (
                                                            <>
                                                                <div className="row">
                                                                    <FormInput type="text" label="N I K" size="2" mandatory={true} val={inputNik} readonly="readonly" onChangeValue={(event) => setInputNik(event.target.value)}/>
                                                                    <FormInput type="select" label="Departemen" size="2" mandatory={true} val={inputDepartemen} readonly="readonly" option={dataDepartemen} defaultSelect={inputDepartemen} onChangeValue={(event) => setInputDepartemen(event)}/>
                                                                    <FormInput type="select" label="Jabatan" size="2" mandatory={true} val={inputJabatan} readonly="readonly" option={dataJabatan} defaultSelect={inputJabatan} onChangeValue={(event) => setInputJabatan(event)}/>
                                                                    <FormInput type="select" label="Level" size="2" mandatory={true} val={inputLevel} readonly="readonly" option={dataLevel} defaultSelect={inputLevel} onChangeValue={(event) => setInputLevel(event)}/>
                                                                    <FormInput type="select" label="Kantor Cabang" size="2" mandatory={true} val={inputCabang} readonly="readonly" option={dataCabang} defaultSelect={inputCabang} onChangeValue={(event) => setInputCabang(event)}/>
                                                                    <FormInput type="select" label="Status Karyawan" size="2" mandatory={true} val={inputStatusKaryawan} readonly="readonly" option={dataStatusKaryawan} defaultSelect={inputStatusKaryawan} onChangeValue={(event) => handleInputStatusKaryawan(event)}/>
                                                                    <FormInput type="date-picker" label="Tanggal Masuk" size="2" mandatory={true} val={inputTanggalMasuk} readonly="readonly" onChangeValue={(event) => setInputTanggalMasuk(event.target.value)}/>
                                                                    {/* {
                                                                        (inputDisplayTanggalBerakhir) && <FormInput type="date-picker" label="Tanggal Berakhir" readonly="readonly" size="2" mandatory={true} val={inputTanggalBerakhir} onChangeValue={(event) => setInputTanggalBerakhir(event.target.value)}/>
                                                                    } */}
                                                                </div>
                                                                {renderSubmit()}
                                                            </>
                                                        )
                                                    // } else if(activeTab == 'Data Payroll') {
                                                    //     return (
                                                    //         <>
                                                    //             <div className="row">
                                                    //                 <div className="col-md-12">
                                                    //                     <h4 className="card-title">Informasi BPJS</h4>
                                                    //                     <hr />
                                                    //                 </div>
                                                    //                     <FormInput type="text" label="No BPJS Ketenagakerjaan" size="2" mandatory={true} val={inputNoBPJSKetenagakerjaan} onChangeValue={(event) => setInputNoBPJSKetenagakerjaan(event.target.value)}/>
                                                    //                     <FormInput type="date-picker" label="Tanggal BPJS Ketenagakerjaan" size="2" mandatory={true} val={inputTanggalBPJSKetenagakerjaan} onChangeValue={(event) => setInputTanggalBPJSKetenagakerjaan(event.target.value)}/>
                                                    //                     <FormInput type="text" label="No BPJS Kesehatan" size="2" mandatory={true} val={inputNoBPJSKesehatan} onChangeValue={(event) => setInputNoBPJSKesehatan(event.target.value)}/>
                                                    //                     <FormInput type="date-picker" label="Tanggal BPJS Kesehatan" size="2" mandatory={true} val={inputTanggalBPJSKesehatan} onChangeValue={(event) => setInputTanggalBPJSKesehatan(event.target.value)}/>
                                                    //                 <div className="col-md-12" style={{marginTop: 20}}>
                                                    //                     <h4 className="card-title">Informasi Rekening</h4>
                                                    //                     <hr />
                                                    //                 </div>
                                                    //                     <FormInput type="select" label="Bank" size="2" mandatory={true} val={inputBank} option={dataBank} defaultSelect={inputBank} onChangeValue={(event) => setInputBank(event)}/>
                                                    //                     <FormInput type="text" label="No Rekening" size="2" mandatory={true} val={inputNoRekening} onChangeValue={(event) => setInputNoRekening(event.target.value)}/>
                                                    //                     <FormInput type="text" label="Atas Nama Rekening" size="2" mandatory={true} val={inputAtasNama} onChangeValue={(event) => setInputAtasNama(event.target.value)}/>
                                                    //                 <div className="col-md-12" style={{marginTop: 20}}>
                                                    //                     <h4 className="card-title">Informasi Pajak</h4>
                                                    //                     <hr />
                                                    //                 </div>
                                                    //                     <FormInput type="text" label="No NPWP" size="2" mandatory={true} val={inputNoNPWP} onChangeValue={(event) => setInputNoNPWP(event.target.value)}/>
                                                    //                     <FormInput type="select" label="Status PTKP" size="2" mandatory={true} val={inputStatusPTKP} option={dataStatusPTKP} defaultSelect={inputStatusPTKP} onChangeValue={(event) => setInputStatusPTKP(event)}/>
                                                    //                     <FormInput type="select" label="Metode Pajak" size="2" mandatory={true} val={inputMetodePajak} option={dataMetodePajak} defaultSelect={inputMetodePajak} onChangeValue={(event) => setInputMetodePajak(event)}/>
                                                    //                 <div className="col-md-12" style={{marginTop: 20}}>
                                                    //                     <h4 className="card-title">Informasi Gaji</h4>
                                                    //                     <hr />
                                                    //                 </div>
                                                    //                     <FormInput type="text" label="Gaji Pokok" size="2" mandatory={true} val={inputGajiPokok} onChangeValue={(event) => setInputGajiPokok(event.target.value)}/>
                                                    //                     <FormInput type="text" label="Gaji BPJS" size="2" mandatory={true} val={inputGajiBPJS} onChangeValue={(event) => setInputGajiBPJS(event.target.value)}/>
                                                    //             </div>
                                                    //             {renderSubmit()}
                                                    //         </>
                                                    //     )
                                                    }
                                                }
                                            })()}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Detail;