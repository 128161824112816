import axios from 'axios';
import APP_CONFIG from '../../Constants/App';
import APP_STORAGE from '../../Storage';

export const baseUrl = (APP_CONFIG.isProduction) ? APP_CONFIG.EndPointProduction : APP_CONFIG.EndPointStaging;

class ApiPenggajian {
    // async getCollections() {
    //     let accessToken = APP_STORAGE.getAccessToken();
        
    //     return axios.get(baseUrl + '/api/v1/app_karyawan/collections',
    //             { 
    //                 headers: { 
    //                     Authorization: 'Bearer ' + accessToken 
    //                 } 
    //             })
    //             .then(response => {
    //                 if(response.data.status == "401") {
    //                     APP_STORAGE.clearLocalStorage();
    //                 } else {
    //                     return response.data; 
    //                 }
    //             })
    //             .catch(error => {
    //                 return error.response;
    //             });
    // }

    async getAll(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/payroll/gaji/get_all', params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    return response.data;
                    // if(response.data.status == "401") {
                    //     APP_STORAGE.clearLocalStorage();
                    // } else {
                    //     return response.data; 
                    // }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async getAllDetail(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/payroll/gaji/get_all_detail', params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async get_current() {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/payroll/gaji/get_current',
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async get(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/payroll/gaji/get/'+ params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    }
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async downloadGaji(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios({
                url: baseUrl + '/api/v1/payroll/gaji/download_gaji/' + params,
                method: 'GET',
                headers: { Authorization: 'Bearer ' + accessToken },
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Rincian Gaji.xlsx');
                document.body.appendChild(link);
                link.click();

                return { status: '100' };
            })
            .catch(error => {
                return error.response;
            });

        // return axios.get(baseUrl + '/api/v1/payroll/gaji/export_komponen_gaji',
        //         { 
        //             headers: { 
        //                 Authorization: 'Bearer ' + accessToken,
        //             },
        //         }, { responseType: 'blob' },)
        //         .then(response => {
        //             if(response.data.status == "401") {
        //                 APP_STORAGE.clearLocalStorage();
        //             } else {
        //                 return response.data; 
        //             }
        //         })
        //         .catch(error => {
        //             return error.response;
        //         });
    }

    async reportGaji(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios({
                url: baseUrl + '/api/v1/payroll/gaji/report_gaji/' + btoa(params),
                method: 'GET',
                headers: { Authorization: 'Bearer ' + accessToken },
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Report Gaji.xlsx');
                document.body.appendChild(link);
                link.click();

                return { status: '100' };
            })
            .catch(error => {
                return error.response;
            });
    }

    async downloadKomponen() {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios({
                url: baseUrl + '/api/v1/payroll/gaji/export_komponen_gaji',
                method: 'GET',
                headers: { Authorization: 'Bearer ' + accessToken },
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Template Komponen Gaji.xlsx');
                document.body.appendChild(link);
                link.click();
            });

        // return axios.get(baseUrl + '/api/v1/payroll/gaji/export_komponen_gaji',
        //         { 
        //             headers: { 
        //                 Authorization: 'Bearer ' + accessToken,
        //             },
        //         }, { responseType: 'blob' },)
        //         .then(response => {
        //             if(response.data.status == "401") {
        //                 APP_STORAGE.clearLocalStorage();
        //             } else {
        //                 return response.data; 
        //             }
        //         })
        //         .catch(error => {
        //             return error.response;
        //         });
    }

    async downloadAbsensi() {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios({
                url: baseUrl + '/api/v1/payroll/gaji/export_absensi',
                method: 'GET',
                headers: { Authorization: 'Bearer ' + accessToken },
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Template Absensi.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        }

    // async getPayroll(params) {
    //     let accessToken = APP_STORAGE.getAccessToken();
        
    //     return axios.get(baseUrl + '/api/v1/app_karyawan/get_payroll/'+ params,
    //             { 
    //                 headers: { 
    //                     Authorization: 'Bearer ' + accessToken 
    //                 } 
    //             })
    //             .then(response => {
    //                 if(response.data.status == "401") {
    //                     APP_STORAGE.clearLocalStorage();
    //                 } else {
    //                     return response.data; 
    //                 }
    //             })
    //             .catch(error => {
    //                 return error.response;
    //             });
    // }
    
    async create(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/payroll/gaji/create', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async updateKomponen(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/payroll/gaji/update_komponen', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async closing(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/payroll/gaji/closing', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async cancel(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/payroll/gaji/cancel', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async uploadAbsensi(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        const formData = new FormData();
        formData.append('file_absensi', formSubmit.file_absensi);
        formData.append('tanggal_awal', formSubmit.tanggal_awal);
        formData.append('tanggal_akhir', formSubmit.tanggal_akhir);

        return axios.post(baseUrl + '/api/v1/payroll/gaji/upload_absensi', formData,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken,
                        "Content-Type": "multipart/form-data"
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async uploadKomponen(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        const formData = new FormData();
        formData.append('file_gaji', formSubmit.file_absensi);
        formData.append('tanggal_awal', formSubmit.tanggal_awal);
        formData.append('tanggal_akhir', formSubmit.tanggal_akhir);

        return axios.post(baseUrl + '/api/v1/payroll/gaji/upload_komponen', formData,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken,
                        "Content-Type": "multipart/form-data"
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async updatepph21(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/payroll/gaji/update_pph21', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async get_slip_gaji() {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/payroll/gaji/get_slip_gaji',
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    }
                })
                .then(response => {
                    return response.data; 
                    // if(response.data.status == "401") {
                    //     APP_STORAGE.clearLocalStorage();
                    // } else {
                    //     return response.data; 
                    // }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async reportPPH(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios({
                url: baseUrl + '/api/v1/payroll/gaji/report_pph/' + btoa(params),
                method: 'GET',
                headers: { Authorization: 'Bearer ' + accessToken },
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Report PPH.csv');
                document.body.appendChild(link);
                link.click();

                return { status: '100' };
            })
            .catch(error => {
                return error.response;
            });
    }

    async get_bpjs_collections() {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/payroll/gaji/get_bpjs_collections',
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    }
                })
                .then(response => {
                    return response.data; 
                    // if(response.data.status == "401") {
                    //     APP_STORAGE.clearLocalStorage();
                    // } else {
                    //     return response.data; 
                    // }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async reportBPJS(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios({
                url: baseUrl + '/api/v1/payroll/gaji/report_bpjs/' + btoa(params),
                method: 'GET',
                headers: { Authorization: 'Bearer ' + accessToken },
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Report BPJS.xlsx');
                document.body.appendChild(link);
                link.click();

                return { status: '100' };
            })
            .catch(error => {
                return error.response;
            });
    }

    async sendSlip(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/job/send_slip_gaji', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async executeSlipGaji(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/job/execute_slip_gaji',
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    
                })
                .catch(error => {
                   
                });
    }

    // async update(formSubmit) {
    //     let accessToken = APP_STORAGE.getAccessToken();
        
    //     return axios.post(baseUrl + '/api/v1/app_karyawan/update', formSubmit,
    //             { 
    //                 headers: { 
    //                     Authorization: 'Bearer ' + accessToken 
    //                 } 
    //             })
    //             .then(response => {
    //                 if(response.data.status == "401") {
    //                     APP_STORAGE.clearLocalStorage();
    //                 } else {
    //                     return response.data; 
    //                 }
    //             })
    //             .catch(error => {
    //                 return error.response;
    //             });
    // }

    // async updatePayroll(formSubmit) {
    //     let accessToken = APP_STORAGE.getAccessToken();
        
    //     return axios.post(baseUrl + '/api/v1/app_karyawan/update_payroll', formSubmit,
    //             { 
    //                 headers: { 
    //                     Authorization: 'Bearer ' + accessToken 
    //                 } 
    //             })
    //             .then(response => {
    //                 if(response.data.status == "401") {
    //                     APP_STORAGE.clearLocalStorage();
    //                 } else {
    //                     return response.data; 
    //                 }
    //             })
    //             .catch(error => {
    //                 return error.response;
    //             });
    // }

    // async getSelect() {
    //     let accessToken = APP_STORAGE.getAccessToken();
        
    //     return axios.get(baseUrl + '/api/v1/app_karyawan/get_select', 
    //             { 
    //                 headers: { 
    //                     Authorization: 'Bearer ' + accessToken 
    //                 } 
    //             })
    //             .then(response => {
    //                 if(response.data.status == "401") {
    //                     APP_STORAGE.clearLocalStorage();
    //                 } else {
    //                     return response.data; 
    //                 }
    //             })
    //             .catch(error => {
    //                 console.log(error)
    //             });
    // }
}

const PenggajianEndpoint = new ApiPenggajian();

export default PenggajianEndpoint;
