import axios from 'axios';
import APP_CONFIG from '../../Constants/App';
import APP_STORAGE from '../../Storage';

export const baseUrl = (APP_CONFIG.isProduction) ? APP_CONFIG.EndPointProduction : APP_CONFIG.EndPointStaging;

class ApiUsers {
    async getAll(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/users/get_all', params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    return response.data;
                    // if(response.data.status == "401") {
                    //     console.log('nah loh');
                    //     // APP_STORAGE.clearLocalStorage();
                    // } else {
                    //     return response.data; 
                    // }
                })
                .catch(error => {
                    console.log(error)
                });
    }

    async getCollections() {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/users/collections',
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async get(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/users/get/'+ params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async create(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/users/create', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async update(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        return axios.post(baseUrl + '/api/v1/users/update', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async updatestatus(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        return axios.post(baseUrl + '/api/v1/users/updatestatus', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async resetpassword(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        return axios.post(baseUrl + '/api/v1/users/resetpassword', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }
}

const UsersEndpoint = new ApiUsers();

export default UsersEndpoint;
