import React, { useRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function ElementAlert(props) {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        props.handleClose();
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
        
    return (
        // autoHideDuration={2000} 
        <Snackbar open={props.open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{marginTop: 50}}> 
            <Alert onClose={handleClose} severity={props.style}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}


export default ElementAlert;