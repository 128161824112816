import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { _ } from 'gridjs-react';
import PageTitle from '../../../Components/Page/PageTitle';
import PageActionDirect from '../../../Components/Page/PageActionDirect';
import APP_STORAGE from '../../../Storage';
import { useNavigate } from "react-router-dom";
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderList from '../../../Components/Element/ElementPreloaderList';
import PageTable from '../../../Components/PageTable';
import APP_MODEL_THR from '../../../Models/Payroll/thr';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import CommonEndpoint from '../../../Models/common';
import FormInput from '../../../Components/FormInput';

function THR(props) {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
        let urlAction       = useParams();
        let urlID           = urlAction.id;
    /* ----------
    Page Vars
    ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("THR");
        const [actionMenu, setActionMenu] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -------------------
        const [tableData, setTableData] = useState([]);
        const [tableParams, setTableParams] = useState([]);
        const [tablePagging, setTablePagging] = useState([]);
        // -------------------
        const [searchFunction, setSearchFunction] = useState(false);
        const [searchNama, setSearchNama] = useState('');
        const [currentStatus, setCurrentStatus] = useState('');
        // -------------------
        const rejectCancelStatus = [2];
        const rejectApproveStatus = [2];
        const rejectUploadStatus = [2];
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let search = {
                // nama_karyawan: searchNama,
            };

            let userMatrix = APP_STORAGE.getUserMatrix();
            let data = await APP_MODEL_THR.getAll(search);
            
            if(data.status === "401") {
                APP_STORAGE.clearLocalStorage();
                navigate("/login");
            }
            
            setActionMenu(userMatrix.filter(item => item.menu_parent == dataID));
            
            refreshTable(data);
            setIsLoading(false);
            
            return () => {
                setTableData([]);
                setTableParams([]);
                setTablePagging([]);
            };
        }, []);
        
        const DataTables = {
            header: [
                { label: 'Tanggal THR', align: 'center', width: '10%' },
                { label: 'Status', align: 'center', width: '15%' },
                { label: 'Action', align: 'center', width: '5%' },
            ],
            data: [
                { field: 'tgl_thr', align: 'center', type: 'text' },
                { field: 'keterangan_status', align: 'center', type: 'badge' },
                { field: actionMenu, align: 'center', type: 'action' },
            ]  
        }
    /* ----------
    Method
    ------------------ */
        async function handleActionMenu(data) {
            let validate    = '';
            let actionInCurrent = false;
            let split_url       = data.url.split("/");
            
            if(data.act == '/thr/cancel') {
                validate = rejectCancelStatus.includes(data.status);
            } else if(data.act == '/thr/approve') {
                validate = rejectApproveStatus.includes(data.status);
            } else if(data.act == '/thr/upload') {
                validate = rejectUploadStatus.includes(data.status);
            } else if(data.act == '/thr/download') {
                actionInCurrent = true;
            }

            data.event.preventDefault();

            if(validate) {
                setAlertMessage("Proses tidak bisa dilakukan");
                setAlertOpen(true);
                setAlertStyle("error");
            } else {
                if(actionInCurrent) {
                    if(data.act === '/thr/download') {
                        let idDownload        = APP_HELPERS_COMMON.dcrypt(split_url[3]);
                        let split_id_download = idDownload.split("|");

                        let resDownload = await APP_MODEL_THR.downloadTHR(split_id_download[0]);

                        if(resDownload.status !== '100') {
                            setAlertMessage("Download rincian gagal");
                            setAlertOpen(true);
                            setAlertStyle("error");
                        }
                    }
                } else {
                    navigate(data.url);
                }
            }
        }

        async function handleUrl(url) {
            let search = {
                // nama_karyawan: searchNama,
            };

            let data = await CommonEndpoint.get_pagging(url, search);

            refreshTable(data);
        }

        async function handleSubmitSearch(e) {
            e.preventDefault();

            let search = {
                // nama_karyawan: searchNama,
            };

            let data = await APP_MODEL_THR.getAll(search);
            
            refreshTable(data);
        }

        function refreshTable(data) {
            setTableData(data.data.data);
            setTableParams(data.data);
            setTablePagging(data.data.links);
        }

    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                {
                    (isLoading) ? 
                        <ElementPreloaderList />
                    :
                    <>
                        <div className="row mb-3">
                            <div className="col-lg-12" style={{textAlign:'right'}}>
                                <PageActionDirect actionMenu={actionMenu} handleActionMenu={(data) => handleActionMenu(data)} />
                                {
                                    (searchFunction) ? 
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Search <i className="mdi mdi-chevron-down"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-md p-4" style={{ width: 350 }}>
                                                <FormInput type="text-search" label="Nama Karyawan" val={searchNama} onChangeValue={(event) => setSearchNama(event.target.value)}/>
                                                <FormInput type="submit-search" label="Search" handleSubmitSearch={(event) => handleSubmitSearch(event)}/>
                                            </div>
                                        </div>
                                    : null
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <PageTable layout={DataTables} item={tableData} count={tableData.length} params={tableParams} pagging={tablePagging} handleUrl={handleUrl} handleActionMenu={(data) => handleActionMenu(data)}/>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </>
                }
            </div>

            <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
        </div>
    )
}

export default THR;