import React, { useEffect, useState } from 'react';
import PageTitle from '../../../Components/Page/PageTitle';
import { Link, useParams, useNavigate } from 'react-router-dom';
import APP_MODEL_USERS from '../../../Models/UserManagement/users';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import FormInput from '../../../Components/FormInput';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';
import PageTabHeader from '../../../Components/Page/PageTabHeader';



function Add() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
    /* ----------
    Page Vars
    ------------------ */
        const [pageTitle, setPageTitle] = useState("Tambah Users");
        const [pageID, setPageID] = useState(2);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const defaultOption = { value: '0', label: ' - Pilih -' };
        // -----------------
        const [inputNama, setInputNama] = useState('');
        const [inputEmail, setInputEmail] = useState('');
        const [inputRole, setInputRole] = useState(defaultOption);
        const [inputCabang, setInputCabang] = useState(defaultOption);
        const [inputPassword, setInputPassword] = useState('');
        const [inputConfirmPassword, setInputConfirmPassword] = useState('');

        // -----------------
        const [dataRole, setDataRole] = useState([]);
        const [dataCabang, setDataCabang] = useState([]);

    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let resCollections  = await APP_MODEL_USERS.getCollections();

            dataRole.push(...resCollections.collections.role);
            dataCabang.push(...resCollections.collections.cabang);

            setIsLoading(false);

            return () => {
                setDataRole([]);
                setDataCabang([]);
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
    const renderTabs = () => {
        if(tabs.length > 0) {
            return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
        }
    }

    const renderSubmit = () => {
        return <div className="row">
                    <div className="col-md-12" style={{textAlign: 'left'}}>
                        <FormInput type="submit" label="Simpan" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                    </div>
                </div>
    }
        
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }

        const handleSubmit = async (e) => {
            e.preventDefault();
                
            setIsLoadingSubmit(true);

            const lowerCaseLetters = /[a-z]/g;
            const upperCaseLetters = /[A-Z]/g;
            const numbers = /[0-9]/g;

            if(inputPassword.length < 6) {
                setIsLoadingSubmit(false);
                setAlertMessage("Minimum length 6 Character");
                setAlertOpen(true);
                setAlertStyle("error");
            } else if(!lowerCaseLetters.test(inputPassword)) {
                setIsLoadingSubmit(false);
                setAlertMessage("Minimum 1 Lower Case : a, b, c, d, e, ...");
                setAlertOpen(true);
                setAlertStyle("error");
            } else if(!upperCaseLetters.test(inputPassword)) {
                setIsLoadingSubmit(false);
                setAlertMessage("Minimum 1 Upper Case : A, B, C, D, E ..");
                setAlertOpen(true);
                setAlertStyle("error");
            } else if(!numbers.test(inputPassword)) {
                setIsLoadingSubmit(false);
                setAlertMessage("Minimum 1 Numeric : 0, 1, 2, 3, 4, 5, 6, 7, 8, 9");
                setAlertOpen(true);
                setAlertStyle("error");
            } else if(inputPassword !== inputConfirmPassword) {
                setIsLoadingSubmit(false);
                setAlertMessage("Password Tidak Sama");
                setAlertOpen(true);
                setAlertStyle("error");
            } else {
                const formSubmit = {
                    name: inputNama,
                    email: inputEmail,
                    role_id: inputRole.value,
                    cabang_id: inputCabang.value,
                    password: inputPassword,
                    confirm_password: inputConfirmPassword 
                }
            
                let res = await APP_MODEL_USERS.create(formSubmit);

                if(res.status == '100') {
                    setAlertMessage(res.message);
                    setAlertOpen(true);
                    setAlertStyle("success");

                    setTimeout(() => {
                        navigate("/user/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
                    }, 2000);
                    
                } else {
                    setIsLoadingSubmit(false);
                    setAlertMessage(res.message);
                    setAlertOpen(true);
                    setAlertStyle("error");
                }
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="text" label="Nama" size="2" mandatory={true} val={inputNama} onChangeValue={(event) => setInputNama(event.target.value)}/>
                                                            <FormInput type="text" label="Email" size="2" mandatory={true} val={inputEmail} onChangeValue={(event) => setInputEmail(event.target.value)}/>
                                                            <FormInput type="select" label="Group Role" size="2" mandatory={true} val={inputRole} option={dataRole} onChangeValue={(event) => setInputRole(event)}/>
                                                            <FormInput type="select" label="Kantor Cabang" size="2" mandatory={true} val={inputCabang} option={dataCabang} onChangeValue={(event) => setInputCabang(event)}/>
                                                            <FormInput type="password" label="Password" size="2" mandatory={true} val={inputPassword} onChangeValue={(event) => setInputPassword(event.target.value)}/>
                                                            <FormInput type="password" label="Confirm Password" size="2" mandatory={true} val={inputConfirmPassword} onChangeValue={(event) => setInputConfirmPassword(event.target.value)}/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Simpan" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Add;