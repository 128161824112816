import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import APP_STORAGE from '../../../Storage';
import './Sidebar.css';
import SingleMenu from './SingleMenu';

function Sidebar() {
	const [sideMenu, setSideMenu] = useState([]);

	const user = {
        name: APP_STORAGE.getName(),
        email: APP_STORAGE.getEmail(),
    }

	useEffect(() => {
        setSideMenu(APP_STORAGE.getPrivileges())
        
        return () => {
            
        }
    }, []);

	// const sideMenu = [
	// 	{
	// 		id: 1,
	// 		label: 'Dashboard',
	// 		icon: 'bx bx-home fa-lg',
	// 		to: '/',
	// 		children: []
	// 	},
	// 	{
	// 		id: 2,
	// 		label: 'User Management',
	// 		icon: 'bx bx-group fa-lg',
	// 		to: '/',
	// 		children: [
	// 			{
	// 				id: 3,
	// 				label: 'Users',
	// 				icon: 'bx bx-user fa-lg',
	// 				to: '/users',
	// 			},
	// 			{
	// 				id: 4,
	// 				label: 'Roles',
	// 				icon: 'bx bx-git-merge fa-lg',
	// 				to: '/roles',
	// 				children: [
	// 					{
	// 						id: 5,
	// 						label: 'Add',
	// 						icon: '',
	// 						to: '/role/add',
	// 					},
	// 					{
	// 						id: 6,
	// 						label: 'Edit',
	// 						icon: '',
	// 						to: '/role/edit',
	// 					},
	// 				],
	// 			},
	// 	  	],
	// 	},
	// 	{
	// 		id: 7,
	// 		label: 'Referensi',
	// 		icon: 'bx bxs-grid fa-lg',
	// 		to: '/',
	// 		children: [
	// 			{
	// 				id: 8,
	// 				label: 'Karyawan',
	// 				icon: '',
	// 				to: '/karyawan',
	// 				children: [
	// 					{
	// 						id: 9,
	// 						label: 'Add',
	// 						icon: '',
	// 						to: '/karyawan/add',
	// 					},
	// 					{
	// 						id: 10,
	// 						label: 'Edit',
	// 						icon: '',
	// 						to: '/karyawan/edit',
	// 					},
	// 				],
	// 			},
	// 			{
	// 				id: 11,
	// 				label: 'Departement',
	// 				icon: '',
	// 				to: '/departement',
	// 				children: [
	// 					{
	// 						id: 12,
	// 						label: 'Add',
	// 						icon: '',
	// 						to: '/departement/add',
	// 					},
	// 					{
	// 						id: 13,
	// 						label: 'Edit',
	// 						icon: '',
	// 						to: '/departement/edit',
	// 					},
	// 				],
	// 			},
	// 	  	],
	// 	},
	// ];

	function handleToogle(id) {
		let elem = document.querySelector('#parent_' + id);
		let findClass = elem.classList.contains("open");

		if(findClass) {
			document.getElementById('parent_' + id).className = "parent dropdown-btn close";
			document.getElementById('sub_' + id).className = "children dropdown-container";
		} else {
			document.getElementById('parent_' + id).className = "parent dropdown-btn open";
			document.getElementById('sub_' + id).className = "children dropdown-container dropdown-container-show";
		}
	}

    return (
		<>
			<div className="vertical-menu">
				<div className="sidenav" style={{ marginTop: 80 }}>
					{/* <div className="card-body">
						<div className="accordion accordion-flush" id="accordionFlushExample">
							{
								sideMenu.map(function(item, index) {
									return <div className="accordion-item" style={{borderBottom: 0}}>
												<h2 className="accordion-header" id="flush-headingOne" style={{borderBottom: 0}}>
													<button className="accordion-button fw-medium collapsed"  style={{borderBottom: 0}} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse_" + index} aria-expanded="false" aria-controls="flush-collapseOne">
														<i className={ item.menu_icon } style={{ width: 30 }}></i>{ item.menu_name }
													</button>
												</h2>
												<div id={"collapse_" + index} className="accordion-collapse collapse" style={{borderBottom: 0, height: 30}} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
													{
														item.children.map(function(item_children, index_children) {
															return (
																<div className="accordion-body text-muted" style={{height: 30}}>{item_children.menu_name}</div>
															)
														})
													}
												</div>
											</div>
								})
							}
						</div>
					</div> */}
					{
						sideMenu.map(function(item, index) {
							return item.children.length > 0 ?
								<>
									<button id={"parent_" + item.menu_id} key={item.menu_id} className="parent dropdown-btn close" onClick={() => handleToogle(item.menu_id)}><i className={ item.menu_icon } style={{ width: 30 }}></i> { item.menu_name }
										<i className="fas fa-angle-down"></i>
									</button>
									<div id={"sub_" + item.menu_id} className="children dropdown-container">
									{
										item.children.map(function(item_children, index_children) {
											return (
												<SingleMenu item={item_children} key={item_children.menu_id} key={'child_' + index_children} />
											)
										})
									}
									</div>
								</>
							:
							<SingleMenu item={item} key={item.menu_id} icon={item.menu_icon} key={'parent_' + index} />
							// return <SingleMenu item={item} key={item.menu_id} icon={item.menu_icon} />
						})
					}
				</div>
			</div>
		</>
    )
}

export default Sidebar;