import React from 'react';

function PageTableHeader(props) {
    return (
        <thead>
            <tr>
                {
                    props.data.map(function(item, index) {
                        return <th style={{ textAlign: item.align, width: item.width }} key={index}>{ item.label }</th>
                    })
                }
            </tr>
        </thead>
    )
}

export default PageTableHeader;