import React, { useEffect, useState } from 'react';
import Header from '../../Components/Layout/Header';
import PageTitle from '../../Components/Page/PageTitle';
import APP_HELPERS_COMMON from '../../Helpers/Common';

function Dashboard() {
    /* ----------
    URL Params
    ------------------ */

    /* ----------
    Page Vars
    ------------------ */
    const [pageTitle, setPageTitle] = useState("Dashboard");
    const test = '9';
    /* ----------
    Page Data
    ------------------ */
    useEffect(async () => {
        let cr = APP_HELPERS_COMMON.crypt(test);
        let dc = APP_HELPERS_COMMON.dcrypt(cr);

        console.log('ENC : ', cr);
        console.log('DECC : ', dc);
    });
        
    /* ----------
    Methods
    ------------------ */
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
            </div>
        </div>
    )
}

export default Dashboard;