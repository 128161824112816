import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import PageTitle from '../../../Components/Page/PageTitle';
import APP_MODEL_HOLIDAY from '../../../Models/ReferensiPayroll/holiday';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';
import APP_HELPERS_COMMON from '../../../Helpers/Common';

function Edit() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
        let urlAction       = useParams();
        let urlID           = urlAction.id;
    /* ----------
    Page Vars
    ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Ubah Holiday");
        const [pageID, setPageID] = useState(111);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputTanggal, setInputTanggal] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputKeterangan, setInputKeterangan] = useState('');
        const [inputStatus, setInputStatus] = useState('');
        // -----------------
        const defaultOption = { value: '0', label: ' - Pilih -' };
        const [dataStatus, setDataStatus] = useState([defaultOption]);
        const [searchNama, setSearchNama] = useState('');
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let res = await APP_MODEL_HOLIDAY.get(dataID);
            
            setInputTanggal(res.data.tanggal);
            setInputKeterangan(res.data.keterangan);
            setInputStatus({ value: res.data.status, label: res.data.keterangan_status });

            dataStatus.push(...res.collections.status);
            
            setIsLoading(false);

            return () => {
                
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }

        const handleSubmit = async (e) => {
            e.preventDefault();
            
            setIsLoadingSubmit(true);

            const formSubmit = {
                holiday_id: dataID,
                tanggal: inputTanggal,
                keterangan: inputKeterangan,
                status: inputStatus.value
            }

            let res = await APP_MODEL_HOLIDAY.update(formSubmit);

            if(res.status === '100') {
                navigate("/holiday/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));

                // setAlertMessage(res.message);
                // setAlertOpen(true);
                // setAlertStyle("success");

                // setTimeout(() => {
                    
                // }, 2000);
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="date-picker" label="Tanggal" size="2" mandatory={true} val={inputTanggal} onChangeValue={(event) => setInputTanggal(event.target.value)}/>
                                                            <FormInput type="text" label="Keterangan" size="2" mandatory={true} val={inputKeterangan} onChangeValue={(event) => setInputKeterangan(event.target.value)}/>
                                                            <FormInput type="select" label="Status" size="2" mandatory={true} val={inputStatus} option={dataStatus} defaultSelect={inputStatus} onChangeValue={(val) => setInputStatus(val)}/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Simpan" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Edit;