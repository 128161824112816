import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import PageTitle from '../../../Components/Page/PageTitle';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import APP_MODEL_ROLES from '../../../Models/UserManagement/roles';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';
import PrivilegeChecklist from './PrivilegeChecklist';

function Privilege() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
        let urlAction       = useParams();
        let urlID           = urlAction.id;
    /* ----------
    Page Vars
    ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Privilege Role");
        const [pageID, setPageID] = useState(6);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputNama, setInputNama] = useState('');
        const [inputPrivilege, setInputPrivilege] = useState([]);
        const [dataPrivilege, setDataPrivilege] = useState([]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let rsRoles = await APP_MODEL_ROLES.get(dataID);
            
            setInputNama(rsRoles.data.name);
            setDataPrivilege(rsRoles.menu);
            setInputPrivilege(rsRoles.privilege);
            
            setIsLoading(false);
            
            return () => {
                
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }
        
        function handleChangePrivilege(item) {
            if(!item.target.checked) {
                const filterPrivilege = inputPrivilege.filter(function(data) {
                    return data.menu_id != item.target.value;
                });

                setInputPrivilege(filterPrivilege);
            } else {
                setInputPrivilege([...inputPrivilege, {
                    menu_id: item.target.value
                }]);
            }
            // console.log(item.target.value, item.target.checked);
        }

        const handleSubmit = async (e) => {
            e.preventDefault();

            setIsLoadingSubmit(true);
            
            const formSubmit = {
                id: dataID,
                privilege: inputPrivilege,
            }
            
            let res = await APP_MODEL_ROLES.updatePrivilege(formSubmit);

            if(res.status == '100') {
                navigate("/role/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="text" label="Nama" size="1" mandatory={true} val={inputNama} readonly="readonly" onChangeValue={(event) => setInputNama(event.target.value)}/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Update" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                        <PrivilegeChecklist data={dataPrivilege} onChangeValue={(item) => handleChangePrivilege(item)} />
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privilege;