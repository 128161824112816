class Storage {
    async saveCredential(data) {
        localStorage.setItem('MPY_USER_ID', data.profile.id);
        localStorage.setItem('MPY_KARYAWAN_ID', data.profile.karyawan_id);
        localStorage.setItem('MPY_USER_NAME', data.profile.name);
        localStorage.setItem('MPY_USER_EMAIL', data.profile.email);
        localStorage.setItem('MPY_USER_ROLE_ID', data.profile.role_id);
        localStorage.setItem('MPY_TOKEN', data.key.access_token);
        localStorage.setItem('MPY_IS_AUTHENTICATED', true);
        localStorage.setItem('MPY_PRIVILEGES', btoa(JSON.stringify(data.profile.privileges)));
        localStorage.setItem('MPY_MATRIX', btoa(JSON.stringify(data.profile.matrix)));
    }

    getAuthStatus = () => {
        return JSON.parse(localStorage.getItem('MPY_IS_AUTHENTICATED'));
    }

    getAccessToken = () => {
        return localStorage.getItem('MPY_TOKEN');
    }

    getName = () => {
        return localStorage.getItem('MPY_USER_NAME');
    }

    getEmail = () => {
        return localStorage.getItem('MPY_USER_EMAIL');
    }

    getPrivileges = () => {
        return JSON.parse(atob(localStorage.getItem('MPY_PRIVILEGES')));
    }

    getUserMatrix = () => {
        return (localStorage.getItem('MPY_MATRIX') === null) ? null : JSON.parse(atob(localStorage.getItem('MPY_MATRIX')));
    }

    getKaryawanId = () => {
        return localStorage.getItem('MPY_KARYAWAN_ID');
    }

    async clearLocalStorage() {
        localStorage.clear();
    }
}

const LocalStorage = new Storage();

export default LocalStorage;
