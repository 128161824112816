import axios from 'axios';
import APP_CONFIG from '../../Constants/App';
import APP_STORAGE from '../../Storage';

export const baseUrl = (APP_CONFIG.isProduction) ? APP_CONFIG.EndPointProduction : APP_CONFIG.EndPointStaging;

class ApiJabatan {
    async getAll(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/referensi/jabatan/get_all', params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    return response.data; 
                    // if(response.data.status == "401") {
                    //     APP_STORAGE.clearLocalStorage();
                    // } else {
                    //     return response.data; 
                    // }
                })
                .catch(error => {
                    console.log(error)
                });
    }

    async getSelectStatus() {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/referensi/jabatan/get_select_status',
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async get(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/referensi/jabatan/get/' + params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    console.log('DISINI')
                    return error.response;
                });
    }

    async create(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/referensi/jabatan/create', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async update(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/referensi/jabatan/update', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async downloadTunjangan() {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios({
                url: baseUrl + '/api/v1/referensi/jabatan/download_tunjangan',
                method: 'GET',
                headers: { Authorization: 'Bearer ' + accessToken },
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Tunjangan Jabatan.xlsx');
                document.body.appendChild(link);
                link.click();

                return { status: '100' };
            })
            .catch(error => {
                return error.response;
            });
    }

    async uploadTunjangan(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        const formData = new FormData();
        formData.append('file_tunjangan', formSubmit.file_gaji);

        return axios.post(baseUrl + '/api/v1/referensi/jabatan/upload_tunjangan', formData,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken,
                        "Content-Type": "multipart/form-data"
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }
}

const ReferensiJabatanEndpoint = new ApiJabatan();

export default ReferensiJabatanEndpoint;
