import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { _ } from 'gridjs-react';
import PageTitle from '../../../Components/Page/PageTitle';
import PageActionDirect from '../../../Components/Page/PageActionDirect';
import APP_STORAGE from '../../../Storage';
import { useNavigate } from "react-router-dom";
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderList from '../../../Components/Element/ElementPreloaderList';
import PageTable from '../../../Components/PageTable';
import APP_MODEL_KARYAWAN from '../../../Models/Karyawan/datakaryawan';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import CommonEndpoint from '../../../Models/common';
import FormInput from '../../../Components/FormInput';

function Karyawan(props) {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
        let urlAction       = useParams();
        let urlID           = urlAction.id;
    /* ----------
    Page Vars
    ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Karyawan");
        const [actionMenu, setActionMenu] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
        // -------------------
        const defaultStatusOption = { value: '1', label: 'Aktif' };
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -------------------
        const [tableData, setTableData] = useState([]);
        const [tableParams, setTableParams] = useState([]);
        const [tablePagging, setTablePagging] = useState([]);
        // -------------------
        const [searchNik, setSearchNik] = useState('');
        const [searchNama, setSearchNama] = useState('');
        const [searchCabang, setSearchCabang] = useState('');
        const [searchDepartemen, setSearchDepartemen] = useState('');
        const [searchJabatan, setSearchJabatan] = useState('');
        const [searchStatus, setSearchStatus] = useState(defaultStatusOption);
        // -------------------
        const rejectEditStatus = [];
        const rejectPayrollStatus = [];
        const rejectNonAktifStatus = [2];
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let search = {
                nama_karyawan: searchNama,
            };
            
            let userMatrix = APP_STORAGE.getUserMatrix();
            let data = await APP_MODEL_KARYAWAN.getAll(search);
            
            if(data.status === "401") {
                APP_STORAGE.clearLocalStorage();
                navigate("/login");
            }
            
            setActionMenu(userMatrix.filter(item => item.menu_parent == dataID));
            refreshTable(data);
            setIsLoading(false);
            
            return () => {
                setTableData([]);
                setTableParams([]);
                setTablePagging([]);
            };
        }, []);
        
        const DataTables = {
            header: [
                { label: 'NIK', align: 'center', width: '15%' },
                { label: 'Nama Karyawan', align: 'center', width: '25%' },
                { label: 'Kantor Cabang', align: 'center', width: '15%' },
                { label: 'Departemen', align: 'center', width: '15%' },
                { label: 'Jabatan', align: 'center', width: '15%' },
                { label: 'Status', align: 'center', width: '10%' },
                { label: 'Action', align: 'center', width: '5%' },
            ],
            data: [
                { field: 'nik', align: 'center', type: 'text' },
                { field: 'nama_karyawan', align: 'left', type: 'text' },
                { field: 'nama_cabang', align: 'left', type: 'text' },
                { field: 'nama_departemen', align: 'left', type: 'text' },
                { field: 'nama_jabatan', align: 'center', type: 'text' },
                { field: 'status_aktif', align: 'center', type: 'badge' },
                { field: actionMenu, align: 'center', type: 'action' },
            ]  
        }
    /* ----------
    Method
    ------------------ */
        function handleActionMenu(data) {
            let validate    = '';
            
            if(data.act == '/datakaryawan/edit') {
                validate = rejectEditStatus.includes(data.status);
            } else if(data.act == '/datakaryawan/payroll') {
                validate = rejectPayrollStatus.includes(data.status);
            } else if(data.act == '/datakaryawan/nonaktif') {
                validate = rejectNonAktifStatus.includes(data.status);
            }
            
            data.event.preventDefault();

            if(validate) {
                setAlertMessage("Proses tidak bisa dilakukan");
                setAlertOpen(true);
                setAlertStyle("error");
            } else {
                navigate(data.url);
            }
        }

        async function handleUrl(url) {
            let search = {
                nik: searchNik,
                nama_karyawan: searchNama,
                cabang: searchCabang,
                departemen: searchDepartemen,
                jabatan: searchJabatan,
                status: searchStatus.value,
            };

            let data = await CommonEndpoint.get_pagging(url, search);

            refreshTable(data);
        }

        async function handleSubmitSearch(e) {
            e.preventDefault();

            let search = {
                nik: searchNik,
                nama_karyawan: searchNama,
                cabang: searchCabang,
                departemen: searchDepartemen,
                jabatan: searchJabatan,
                status: searchStatus.value,
            };
            
            let data = await APP_MODEL_KARYAWAN.getAll(search);
            
            refreshTable(data);
        }

        function refreshTable(data) {
            setTableData(data.data.data);
            setTableParams(data.data);
            setTablePagging(data.data.links);
        }

    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                {
                    (isLoading) ? 
                        <ElementPreloaderList />
                    :
                    <>
                        <div className="row mb-3">
                            <div className="col-lg-12" style={{textAlign:'right'}}>
                                <PageActionDirect actionMenu={actionMenu} handleActionMenu={(data) => handleActionMenu(data)} />
                                <div className="btn-group">
                                    <button type="button" className="btn btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Search <i className="mdi mdi-chevron-down"></i>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-md p-4" style={{ width: 350 }}>
                                        <FormInput type="text-search" label="N I K" val={searchNik} onChangeValue={(event) => setSearchNik(event.target.value)}/>
                                        <FormInput type="text-search" label="Nama Karyawan" val={searchNama} onChangeValue={(event) => setSearchNama(event.target.value)}/>
                                        <FormInput type="text-search" label="Kantor Cabang" val={searchCabang} onChangeValue={(event) => setSearchCabang(event.target.value)}/>
                                        <FormInput type="text-search" label="Departemen" val={searchDepartemen} onChangeValue={(event) => setSearchDepartemen(event.target.value)}/>
                                        <FormInput type="text-search" label="Jabatan" val={searchJabatan} onChangeValue={(event) => setSearchJabatan(event.target.value)}/>
                                        <FormInput type="submit-search" label="Search" handleSubmitSearch={(event) => handleSubmitSearch(event)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <PageTable layout={DataTables} item={tableData} count={tableData.length} params={tableParams} pagging={tablePagging} handleUrl={handleUrl} handleActionMenu={(data) => handleActionMenu(data)}/>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </>
                }
            </div>

            <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
        </div>
    )
}

export default Karyawan;

// import React, { useEffect, useState } from 'react';
// import { Link, useParams } from 'react-router-dom';
// import { _ } from 'gridjs-react';
// import PageTitle from '../../../Components/Page/PageTitle';
// import CellAction from '../../../Components/DatatTableGrid/CellAction';
// import PageActionDirect from '../../../Components/Page/PageActionDirect';
// import APP_STORAGE from '../../../Storage';
// import { useNavigate } from "react-router-dom";
// import ElementAlert from '../../../Components/Element/ElementAlert';
// import PageTable from '../../../Components/PageTable';
// import ModelReferensiKaryawan from '../../../Models/Karyawan/datakaryawan';
// import CommonEndpoint from '../../../Models/common';
// import FormInput from '../../../Components/FormInput';

// function Users(props) {
//     /* ----------
//     URL Params
//     ------------------ */
//         let navigate        = useNavigate();
//         let urlAction       = useParams();
//     /* ----------
//     Page Vars
//     ------------------ */
//         const [pageTitle, setPageTitle] = useState("Data Karyawan");
//         const [actionMenu, setActionMenu] = useState([]);
//         const [isLoading, setIsLoading] = useState(true);
//         // -------------------
//         const [alertOpen, setAlertOpen] = useState(false);
//         const [alertMessage, setAlertMessage] = useState(false);
//         const [alertStyle, setAlertStyle] = useState("");
//         // -------------------
//         const [tableData, setTableData] = useState([]);
//         const [tableParams, setTableParams] = useState([]);
//         const [tablePagging, setTablePagging] = useState([]);
//         // -------------------
//         const [searchNik, setSearchNik] = useState('');
//         const [searchNama, setSearchNama] = useState('');
//         // -------------------
//         const rejectEditStatus = [2];
//     /* ----------
//     Page Data
//     ------------------ */
//         useEffect(async () => {
//             let search = {
//                 nik: searchNik,
//                 nama_karyawan: searchNama
//             };

//             let userMatrix = APP_STORAGE.getUserMatrix();
//             let data = await ModelReferensiKaryawan.get_all(search);
            
//             if(data.status == 401) {
//                 navigate("/login");
//             }
            
//             setActionMenu(userMatrix.filter(item => item.menu_parent == urlAction.id));
//             refreshTable(data);
            
//             return () => {
                
//             };
//         }, []);
        
//         const DataTables = {
//             header: [
//                 { label: 'NIK', align: 'center', width: '20%' },
//                 { label: 'Nama', align: 'center', width: '40%' },
//                 { label: 'Status', align: 'center', width: '20%' },
//                 { label: 'Action', align: 'center', width: '25%' },
//             ],
//             data: [
//                 { field: 'nik', align: 'center', type: 'text' },
//                 { field: 'nama_karyawan', align: 'left', type: 'text' },
//                 { field: 'status_code', align: 'center', type: 'badge' },
//                 { field: actionMenu, align: 'center', type: 'action' },
//             ]  
//         }
//     /* ----------
//     Method
//     ------------------ */
//         function handleActionMenu(data) {
//             let validate = rejectEditStatus.includes(data.status);
            
//             data.event.preventDefault();

//             if(validate) {
//                 setAlertMessage("Data tidak bisa dirubah");
//                 setAlertOpen(true);
//                 setAlertStyle("error");
//             } else {
//                 navigate(data.url);
//             }
//         }

//         async function handleUrl(url) {
//             let search = {
//                 nik: searchNik,
//                 nama_karyawan: searchNama
//             };

//             let data = await CommonEndpoint.get_pagging(url, search);

//             refreshTable(data);
//         }

//         async function handleSubmitSearch(e) {
//             e.preventDefault();

//             let search = {
//                 nik: searchNik,
//                 nama_karyawan: searchNama
//             };
            
//             let data = await ModelReferensiKaryawan.get_all(search);
            
//             refreshTable(data);
//         }

//         function refreshTable(data) {
//             setTableData(data.data.data);
//             setTableParams(data.data);
//             setTablePagging(data.data.links);

//             console.log(data.data)
//         }

//     return (
//         <div className="page-content">
//             <div className="container-fluid">
//                 <PageTitle pageTitle={pageTitle} />
//                 <div className="row mb-3">
//                     <div className="col-lg-12" style={{textAlign:'right'}}>
//                         <PageActionDirect actionMenu={actionMenu} handleActionMenu={(data) => handleActionMenu(data)} />
//                         <div className="btn-group">
//                             <button type="button" className="btn btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                 Search <i className="mdi mdi-chevron-down"></i>
//                             </button>
//                             <div className="dropdown-menu dropdown-menu-md p-4" style={{ width: 350 }}>
//                                 <FormInput type="text-search" label="N I K" val={searchNik} onChangeValue={(event) => setSearchNik(event.target.value)}/>
//                                 <FormInput type="text-search" label="Nama" val={searchNama} onChangeValue={(event) => setSearchNama(event.target.value)}/>
//                                 <FormInput type="submit-search" label="Search" handleSubmitSearch={(event) => handleSubmitSearch(event)}/>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="row">
//                     <div className="col-lg-12">
//                         <div className="card">
//                             <div className="card-body">
//                                 <PageTable layout={DataTables} item={tableData} count={tableData.length} params={tableParams} pagging={tablePagging} handleUrl={handleUrl} handleActionMenu={(data) => handleActionMenu(data)}/>
//                             </div>
//                         </div> 
//                     </div>
//                 </div>
//             </div>

//             <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
//         </div>
//     )
// }

// export default Users;