import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import PageTitle from '../../../Components/Page/PageTitle';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import APP_MODEL_REIMBURSE from '../../../Models/Finance/reimburse';
import APP_MODEL_REFERENSI from '../../../Models/Referensi/referensi';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';

function Approve() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
        let urlAction       = useParams();
        let urlID           = urlAction.id;
    /* ----------
    Page Vars
    ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Persetujuan Reimburse");
        const [pageID, setPageID] = useState(60);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const defaultOption = { value: '0', label: ' - Pilih -' };
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputID, setInputID] = useState('');
        const [inputNama, setInputNama] = useState('');
        const [inputTanggal, setInputTanggal] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputTanggalEffekif, setInputTanggalEffektif] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputJenisReimburse, setInputJenisReimburse] = useState('');
        const [inputKeterangan, setInputKeterangan] = useState('');
        const [inputItems, setInputItems] = useState([{ keterangan: '-', nominal: 0 }]);
        const [inputStatus, setInputStatus] = useState({ value: '2', label: 'Approve' });
        const [inputCatatan, setInputCatatan] = useState('');
        const [totalPengajuan, setTotalPengajuan] = useState(0);
        const [totalDisetujui, setTotalDisetujui] = useState(0);
        // -----------------
        const [dataStatus, setDataStatus] = useState([defaultOption]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let resCollections = await APP_MODEL_REIMBURSE.get(dataID);
            
            setInputID(resCollections.data.karyawan_id);
            setInputNama(resCollections.data.nama_karyawan);
            setInputTanggal(resCollections.data.tgl_reimburse);
            setInputTanggalEffektif(resCollections.data.tgl_effektif);
            setInputJenisReimburse(resCollections.data.keterangan_reimburse);
            setInputKeterangan(resCollections.data.keterangan);
            setInputItems(resCollections.collections.items);

            handleTotal(resCollections.collections.items);

            dataStatus.push(...resCollections.collections.status);
            
            setIsLoading(false);

            return () => {
                
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }

        function handleUpdateNominalDisetujui(value, item, index) {
            const updateItem = {
                ...item,
                nominal_realisasi: value
            }
            
            const newBenefit = [...inputItems];
            newBenefit[index] = updateItem;

            handleTotal(newBenefit);
            setInputItems(newBenefit);
        }

        function handleTotal(data) {
            let total = data.reduce((total, currentItem) =>  total = eval(total) + eval(currentItem.nominal), 0);
            let realisasi = data.reduce((total, currentItem) =>  total = eval(total) + eval(currentItem.nominal_realisasi), 0);

            setTotalPengajuan(total);
            setTotalDisetujui(realisasi);
        }

        const handleSubmit = async (e) => {
            e.preventDefault();

            setIsLoadingSubmit(true);
            
            const formSubmit = {
                reimburse_id: dataID,
                karyawan_id: inputID,
                catatan: inputCatatan,
                status: inputStatus.value,
                items: inputItems
            }
            
            let res = await APP_MODEL_REIMBURSE.approve(formSubmit);
            
            if(res.status == '100') {
                navigate("/reimburse/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="text" label="Nama Karyawan" size="2" mandatory={true} readonly="readonly" val={inputNama} onChangeValue={(event) => setInputNama(event.target.value)}/>
                                                            <FormInput type="text" label="Tanggal Reimburse" size="2" mandatory={true} readonly="readonly" val={inputTanggal} onChangeValue={(event) => setInputTanggal(event.target.value)}/>
                                                            <FormInput type="text" label="Tanggal Effektif" size="2" mandatory={true} readonly="readonly" val={inputTanggalEffekif} onChangeValue={(event) => setInputTanggalEffektif(event.target.value)}/>
                                                            <FormInput type="text" label="Jenis Reimburse" size="2" mandatory={true} readonly="readonly" val={inputJenisReimburse} onChangeValue={(event) => setInputJenisReimburse(event.target.value)}/>
                                                            <FormInput type="text" label="Keterangan" size="2" mandatory={true} readonly="readonly" val={inputKeterangan} onChangeValue={(event) => setInputKeterangan(event.target.value)}/>
                                                            <FormInput type="select" label="Status" size="2" mandatory={true} val={inputStatus} option={dataStatus} defaultSelect={inputStatus} onChangeValue={(event) => setInputStatus(event)}/>
                                                            <FormInput type="text" label="Catatan" size="1" mandatory={true} val={inputCatatan} onChangeValue={(event) => setInputCatatan(event.target.value)}/>
                                                        </div>
                                                        <div className="row" style={{marginTop: 20}}>
                                                            <div className="table-responsive">
                                                                <table className="table mb-0">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th style={{textAlign: 'center', width: '60%'}}>Benefit</th>
                                                                            <th style={{textAlign: 'center', width: '15%'}}>Jumlah Pengajuan</th>
                                                                            <th style={{textAlign: 'center', width: '15%'}}>Jumlah Disetujui</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        inputItems.map(function(item, index) {
                                                                            return (
                                                                                <tr>
                                                                                    <th scope="row" style={{textAlign: 'center'}}>
                                                                                        <FormInput type="text-only" label="Keterangan" mandatory={true} readonly="readonly" val={item.keterangan}/>
                                                                                    </th>
                                                                                    <td style={{textAlign: 'right'}}>
                                                                                        <FormInput type="text-only" align="right" label="Nominal" readonly="readonly" mandatory={true} val={APP_HELPERS_COMMON.formatCurrency(item.nominal)}/>
                                                                                    </td>
                                                                                    <td style={{textAlign: 'right'}}>
                                                                                    {/* <FormInput type="currency-only" align="right" label="Nominal" mandatory={true} val={item.nominal_realisasi} onChangeValue={(value) => handleUpdateNominal(value, item, index)}/> */}
                                                                                        <FormInput type="currency-only" align="right" label="Nominal" mandatory={true} val={item.nominal_realisasi} onChangeValue={(value) => handleUpdateNominalDisetujui(value, item, index)}/>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                        <tr>
                                                                            <th scope="row" style={{textAlign: 'right', fontWeight: 'bold'}}>
                                                                                Total
                                                                            </th>
                                                                            <td style={{textAlign: 'right', fontWeight: 'bold', marginRight: 50}}>
                                                                                { APP_HELPERS_COMMON.formatCurrency(totalPengajuan) }
                                                                            </td>
                                                                            <td style={{textAlign: 'right', fontWeight: 'bold', marginRight: 50}}>
                                                                                { APP_HELPERS_COMMON.formatCurrency(totalDisetujui) }
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{marginTop: -15}}>
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Proses" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Approve;