import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import PageTitle from '../../../Components/Page/PageTitle';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import APP_MODEL_KARAYAWAN from '../../../Models/Karyawan/datakaryawan';
import APP_MODEL_PINJAMAN from '../../../Models/Finance/pinjaman';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';

function Approve() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
        let urlAction       = useParams();
        let urlID           = urlAction.id;
    /* ----------
    Page Vars
    ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Persetujuan Pinjaman");
        const [pageID, setPageID] = useState(47);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const defaultOption = { value: '0', label: ' - Pilih -' };
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputNama, setInputNama] = useState('defaultOption');
        const [inputTanggal, setInputTanggal] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputJumlahPinjaman, setInputJumlahPinjaman] = useState(0);
        const [inputJangkaWaktu, setInputJangkaWaktu] = useState(0);
        const [inputSukuBunga, setInputSukuBunga] = useState(0);
        const [inputKeterangan, setInputKeterangan] = useState('');
        const [inputCatatan, setInputCatatan] = useState('');
        const [inputStatus, setInputStatus] = useState({ value: '2', label: 'Approve' });
        const [inputSimulasi, setInputSimulasi] = useState([]);
        // -----------------
        const [dataStatus, setDataStatus] = useState([defaultOption]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let resPinjaman  = await APP_MODEL_PINJAMAN.get(dataID);
            
            setInputNama(resPinjaman.data.nama_karyawan)
            setInputTanggal(resPinjaman.data.tgl_pinjaman);
            setInputJumlahPinjaman(resPinjaman.data.nilai_pinjaman);
            setInputJangkaWaktu(resPinjaman.data.jangka_waktu);
            setInputSukuBunga(resPinjaman.data.suku_bunga);
            setInputKeterangan(resPinjaman.data.keterangan);
            setInputSimulasi(resPinjaman.collections.angsuran);

            dataStatus.push(...resPinjaman.collections.status);

            setIsLoading(false);

            return () => {
                
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }

        const handleSubmit = async (e) => {
            e.preventDefault();

            setIsLoadingSubmit(true);
            
            const formSubmit = {
                pinjaman_id: dataID,
                catatan: inputCatatan,
                status: inputStatus.value,
            }

            let res = await APP_MODEL_PINJAMAN.approve(formSubmit);

            if(res.status == '100') {
                navigate("/pinjaman/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="text" label="Nama Karyawan" size="2" readonly="readonly" mandatory={true} val={inputNama} onChangeValue={(event) => setInputNama(event.target.value)}/>
                                                            <FormInput type="text" label="Tanggal Pinjaman" size="2" readonly="readonly" mandatory={true} val={inputTanggal} onChangeValue={(event) => setInputTanggal(event.target.value)}/>
                                                            <FormInput type="currency" label="Jumlah Pinjaman" size="2" readonly="readonly" mandatory={true} val={inputJumlahPinjaman} onChangeValue={(value) => setInputJumlahPinjaman(value)}/>
                                                            <FormInput type="number" label="Jangka Waktu" size="2" readonly="readonly" mandatory={true} val={inputJangkaWaktu} onChangeValue={(event) => setInputJangkaWaktu(event.target.value)}/>
                                                            <FormInput type="currency" label="% Suku Per Tahun" size="2" readonly="readonly" mandatory={true} val={inputSukuBunga} onChangeValue={(value) => setInputSukuBunga(value)}/>
                                                            <FormInput type="text" label="Keterangan" size="2" readonly="readonly" mandatory={true} val={inputKeterangan} onChangeValue={(event) => setInputKeterangan(event.target.value)}/>
                                                            <FormInput type="select" label="Status" size="2" mandatory={true} val={inputStatus} option={dataStatus} defaultSelect={inputStatus} onChangeValue={(event) => setInputStatus(event)}/>
                                                            <FormInput type="text" label="Catatan" size="2" mandatory={true} val={inputCatatan} onChangeValue={(event) => setInputCatatan(event.target.value)}/>
                                                        </div>
                                                        <div className="row" style={{marginTop: 20}}>
                                                            <div className="table-responsive">
                                                                <table className="table mb-0">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th style={{textAlign: 'center'}}>Periode</th>
                                                                            <th style={{textAlign: 'right'}}>Saldo Pinjaman</th>
                                                                            <th style={{textAlign: 'right'}}>Pokok</th>
                                                                            <th style={{textAlign: 'right'}}>Bunga</th>
                                                                            <th style={{textAlign: 'right'}}>Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        inputSimulasi.map(function(item, index) {
                                                                            return (
                                                                                <tr>
                                                                                    <th scope="row" style={{textAlign: 'center'}}>{item.periode}</th>
                                                                                    <td style={{textAlign: 'right'}}>{APP_HELPERS_COMMON.formatCurrency(item.saldo_pinjaman)}</td>
                                                                                    <td style={{textAlign: 'right'}}>{APP_HELPERS_COMMON.formatCurrency(item.pokok)}</td>
                                                                                    <td style={{textAlign: 'right'}}>{APP_HELPERS_COMMON.formatCurrency(item.bunga)}</td>
                                                                                    <td style={{textAlign: 'right'}}>{APP_HELPERS_COMMON.formatCurrency(item.total)}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }                                                                    
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Simpan" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Approve;