import axios from 'axios';
import APP_CONFIG from '../../Constants/App';
import APP_STORAGE from '../../Storage';

export const baseUrl = (APP_CONFIG.isProduction) ? APP_CONFIG.EndPointProduction : APP_CONFIG.EndPointStaging;

class ApiKaryawan {
    async getCollections() {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/app_karyawan/collections',
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    return response.data; 
                    // if(response.data.status == "401") {
                    //     APP_STORAGE.clearLocalStorage();
                    // } else {
                    //     return response.data; 
                    // }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async getAll(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/app_karyawan/get_all', params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    return response.data; 
                    // if(response.data.status == "401") {
                    //     APP_STORAGE.clearLocalStorage();
                    // } else {
                    //     return response.data; 
                    // }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async get(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/app_karyawan/get/'+ params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async getPayroll(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/app_karyawan/get_payroll/'+ params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }
    
    async create(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/app_karyawan/create', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async update(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/app_karyawan/update', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async updatePayroll(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/app_karyawan/update_payroll', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async updateNonAktif(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/app_karyawan/update_nonaktif', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async getSelect() {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/app_karyawan/get_select', 
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    console.log(error)
                });
    }

    async downloadGaji() {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios({
                url: baseUrl + '/api/v1/app_karyawan/download_gaji',
                method: 'GET',
                headers: { Authorization: 'Bearer ' + accessToken },
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Gaji Karyawan.xlsx');
                document.body.appendChild(link);
                link.click();

                return { status: '100' };
            })
            .catch(error => {
                return error.response;
            });
    }

    async uploadGaji(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        const formData = new FormData();
        formData.append('file_gaji', formSubmit.file_gaji);

        return axios.post(baseUrl + '/api/v1/app_karyawan/upload_gaji', formData,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken,
                        "Content-Type": "multipart/form-data"
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }
}

const ReferensiKaryawanEndpoint = new ApiKaryawan();

export default ReferensiKaryawanEndpoint;
