import React, { useState } from 'react';

function PageActionDirect(props) {
    function handleActionMenu(e, url) {
        let action = {
            event: e,
            act: url,
            url,
            status: 'allow-always'
        }

        props.handleActionMenu(action);
    }
    
    return (
        <div className="btn-group" style={{marginRight:'10px'}}>
        {
                props.actionMenu.map(function(item, index) {
                    if(item.menu_method != 'single') return <button type="button" key={index} className="btn btn-primary waves-effect waves-light" style={{marginLeft: 5}} onClick={(e) => handleActionMenu(e, item.menu_url)}><i className="bx bx-plus me-1"></i> {item.menu_name}</button>
                })
            }
        </div>
    )
}

export default PageActionDirect;