import React, { useEffect, useState } from 'react';
import PageTitle from '../../../Components/Page/PageTitle';
import { Link, useParams, useNavigate } from 'react-router-dom';
import APP_MODEL_USERS from '../../../Models/UserManagement/users';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import FormInput from '../../../Components/FormInput';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';
import PageTabHeader from '../../../Components/Page/PageTabHeader';

function EditStatus() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
        let urlAction       = useParams();
        let urlID           = urlAction.id;
    /* ----------
    Page Vars
    ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Non Aktifkan Users");
        const [pageID, setPageID] = useState(2);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const defaultOption = { value: '0', label: ' - Pilih -' };
        // -----------------
        const [inputId, setInputId] = useState('');
        const [inputNama, setInputNama] = useState('');
        const [inputEmail, setInputEmail] = useState('');
        const [inputRole, setInputRole] = useState('');
        const [inputCabang, setInputCabang] = useState('');
        // -----------------

    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let resUsers  = await APP_MODEL_USERS.get(dataID);

            setInputId(resUsers.data.user_id);
            setInputNama(resUsers.data.name);
            setInputEmail(resUsers.data.email);
            setInputRole(resUsers.data.ket_group_role);
            setInputCabang(resUsers.data.nama_cabang);

            setIsLoading(false);

            return () => {
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
    const renderTabs = () => {
        if(tabs.length > 0) {
            return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
        }
    }

    const renderSubmit = () => {
        return <div className="row">
                    <div className="col-md-12" style={{textAlign: 'left'}}>
                        <FormInput type="submit" label="Non Aktifkan" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                    </div>
                </div>
    }
        
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }

        const handleSubmit = async (e) => {
            e.preventDefault();
                
            setIsLoadingSubmit(true);
            
            const formSubmit = {
                user_id: inputId,
            }
        
            let res = await APP_MODEL_USERS.updatestatus(formSubmit);

            if(res.status == '100') {
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("success");

                setTimeout(() => {
                    navigate("/user/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
                }, 2000);
                
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                        {
                                            (isLoading) ? 
                                                <ElementPreloaderForm />
                                            :
                                                <>
                                                    <div className="row">
                                                        <FormInput type="text" label="Nama" size="2" readonly="readonly" mandatory={true} val={inputNama} onChangeValue={(event) => setInputNama(event.target.value)}/>
                                                        <FormInput type="text" label="Email" size="2" readonly="readonly" mandatory={true} val={inputEmail} onChangeValue={(event) => setInputEmail(event.target.value)}/>
                                                        <FormInput type="text" label="Group Role" size="2" readonly="readonly" mandatory={true} val={inputRole} onChangeValue={(event) => setInputRole(event.target.value)}/>
                                                        <FormInput type="text" label="Kantor Cabang" size="2" readonly="readonly" mandatory={true} val={inputCabang} onChangeValue={(event) => setInputCabang(event.target.value)}/>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12" style={{textAlign: 'left'}}>
                                                            <FormInput type="submit" label="Non Aktifkan" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditStatus;