import axios from 'axios';
import APP_CONFIG from '../../Constants/App';
import APP_STORAGE from '../../Storage';

export const baseUrl = (APP_CONFIG.isProduction) ? APP_CONFIG.EndPointProduction : APP_CONFIG.EndPointStaging;

class ApiHoliday {
    async getAll(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/referensi/holiday/get_all', params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async get(params) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/referensi/holiday/get/' + params,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    console.log('DISINI')
                    return error.response;
                });
    }

    // async getSelectStatus() {
    //     let accessToken = APP_STORAGE.getAccessToken();
        
    //     return axios.get(baseUrl + '/api/v1/role/get_select_status',
    //             { 
    //                 headers: { 
    //                     Authorization: 'Bearer ' + accessToken 
    //                 } 
    //             })
    //             .then(response => {
    //                 if(response.data.status == "401") {
    //                     APP_STORAGE.clearLocalStorage();
    //                 } else {
    //                     return response.data; 
    //                 }
    //             })
    //             .catch(error => {
    //                 return error.response;
    //             });
    // }

    // async getActionMenu(menu_id) {
    //     let accessToken = APP_STORAGE.getAccessToken();
        
    //     return axios.get(baseUrl + '/api/v1/role/get_action_menu/' + menu_id, 
    //             { 
    //                 headers: { 
    //                     Authorization: 'Bearer ' + accessToken 
    //                 } 
    //             })
    //             .then(response => {
    //                 if(response.data.status == "401") {
    //                     APP_STORAGE.clearLocalStorage();
    //                 } else {
    //                     return response.data; 
    //                 }
    //             })
    //             .catch(error => {
    //                 return error.response;
    //             });
    // }

    // async getAllPrivilege(params) {
    //     let accessToken = APP_STORAGE.getAccessToken();
        
    //     return axios.get(baseUrl + '/api/v1/role/get_all_privilege/' + params, 
    //             { 
    //                 headers: { 
    //                     Authorization: 'Bearer ' + accessToken 
    //                 } 
    //             })
    //             .then(response => {
    //                 if(response.data.status == "401") {
    //                     APP_STORAGE.clearLocalStorage();
    //                 } else {
    //                     return response.data; 
    //                 }
    //             })
    //             .catch(error => {
    //                 return error.response;
    //             });
    // }

    async create(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/referensi/holiday/create', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async update(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/referensi/holiday/update', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }

    // async updatePrivilege(formSubmit) {
    //     let accessToken = APP_STORAGE.getAccessToken();
        
    //     return axios.post(baseUrl + '/api/v1/role/update_privilege', formSubmit,
    //             { 
    //                 headers: { 
    //                     Authorization: 'Bearer ' + accessToken 
    //                 } 
    //             })
    //             .then(response => {
    //                 if(response.data.status == "401") {
    //                     APP_STORAGE.clearLocalStorage();
    //                 } else {
    //                     return response.data; 
    //                 }
    //             })
    //             .catch(error => {
    //                 return error.response;
    //             });
    // }
}

const HolidayEndpoint = new ApiHoliday();

export default HolidayEndpoint;
