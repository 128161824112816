import React from 'react';

function PageTablePaggingSimpleDua(props) {
    return (
        <nav aria-label="...">
            { 
            
                (props.params.first_page_url !== props.params.last_page_url) ? 
                    <>
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="pagination" style={{float:'right'}}>
                                    <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => props.handleUrl(props.params.first_page_url)}><i className="mdi mdi-chevron-double-left"></i></a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => props.handleUrl(props.params.prev_page_url)}><i className="mdi mdi-chevron-left"></i></a>
                                    </li>
                                    <li className="page-item">
                                        <p className="page-link">Page {props.params.current_page} from {props.params.last_page}</p>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => props.handleUrl(props.params.next_page_url)}><i className="mdi mdi-chevron-right"></i></a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => props.handleUrl(props.params.last_page_url)}><i className="mdi mdi-chevron-double-right"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                : null
            }
        </nav>
    )
}

export default PageTablePaggingSimpleDua;