import axios from 'axios';
import APP_CONFIG from '../../Constants/App';
import APP_STORAGE from '../../Storage';

export const baseUrl = (APP_CONFIG.isProduction) ? APP_CONFIG.EndPointProduction : APP_CONFIG.EndPointStaging;

class ApiConfig {
    async get() {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.get(baseUrl + '/api/v1/config/get_config',
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    }
                })
                .then(response => {
                    return response.data; 
                    // if(response.data.status == "401") {
                    //     APP_STORAGE.clearLocalStorage();
                    // } else {
                    //     return response.data; 
                    // }
                })
                .catch(error => {
                    return error.response;
                });
    }

    async update(formSubmit) {
        let accessToken = APP_STORAGE.getAccessToken();
        
        return axios.post(baseUrl + '/api/v1/config/update_config', formSubmit,
                { 
                    headers: { 
                        Authorization: 'Bearer ' + accessToken 
                    } 
                })
                .then(response => {
                    if(response.data.status == "401") {
                        APP_STORAGE.clearLocalStorage();
                    } else {
                        return response.data; 
                    }
                })
                .catch(error => {
                    return error.response;
                });
    }
}

const ConfigEndpoint = new ApiConfig();

export default ConfigEndpoint;
