import React from 'react';
import PageTableHeader from './PageTableHeader';
import PageTableBody from './PageTableBody';
import PageTablePagging from './PageTablePagging';
import PageTablePaggingSimple from './PageTablePaggingSimple';

function PageTable(props) {
    return (
        <div>
            <div className="table-responsive" style={{overflowX:'visible'}}>
                <table className="table mb-0">
                    <PageTableHeader data={props.layout.header} />
                    <PageTableBody data={props.item} column={props.layout.header} layout={props.layout.data} count={props.count} handleActionMenu={(data) => props.handleActionMenu(data)}/>
                </table>
            </div>
            <PageTablePaggingSimple params={props.params} count={props.count} pagging={props.pagging} handleUrl={(url) => props.handleUrl(url)} />
        </div>
    )
}

export default PageTable;