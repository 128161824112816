import './style.css'
function ElementPreloaderForm() {
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <label className="form-label placeholder-glow"><span style={{height: 15, width: 100, borderRadius: 5}} className="placeholder"></span></label>
                    <div style={{display:"flex"}} className="placeholder-glow">
                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="form-label placeholder-glow"><span style={{height: 15, width: 100, borderRadius: 5}} className="placeholder"></span></label>
                    <div style={{display:"flex"}} className="placeholder-glow">
                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                    </div>
                </div>
                <div className="col-md-6" style={{marginTop: 20}}>
                    <label className="form-label placeholder-glow"><span style={{height: 15, width: 100, borderRadius: 5}} className="placeholder"></span></label>
                    <div style={{display:"flex"}} className="placeholder-glow">
                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                    </div>
                </div>
                <div className="col-md-6" style={{marginTop: 20}}>
                    <label className="form-label placeholder-glow"><span style={{height: 15, width: 100, borderRadius: 5}} className="placeholder"></span></label>
                    <div style={{display:"flex"}} className="placeholder-glow">
                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                    </div>
                </div>
                <div className="col-md-6" style={{marginTop: 20}}>
                    <label className="form-label placeholder-glow"><span style={{height: 15, width: 100, borderRadius: 5}} className="placeholder"></span></label>
                    <div style={{display:"flex"}} className="placeholder-glow">
                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                    </div>
                </div>
                <div className="col-md-6" style={{marginTop: 20, marginBottom: 20}}>
                    <label className="form-label placeholder-glow"><span style={{height: 15, width: 100, borderRadius: 5}} className="placeholder"></span></label>
                    <div style={{display:"flex"}} className="placeholder-glow">
                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                    </div>
                </div>
                <div className="mt-4 placeholder-glow" style={{marginTop: 50}}>
                    <span style={{height: 35, width: 120, borderRadius: 5}} className="placeholder"></span>
                </div>
            </div>
        </>
    )
}

export default ElementPreloaderForm;